import React, { useState, useCallback } from "react";
import { Autocomplete, TextField, Box, CircularProgress, Typography } from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";

const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const navigate = useNavigate();

  // Utility function to flatten nested data for easier search access
  const flattenData = (cardData) => {
    return {
      ...cardData,
      classifications: cardData.classifications?.join(" ").toLowerCase() || "",
      illustrators: cardData.illustrators?.join(" ").toLowerCase() || "",
      name: cardData.name?.toLowerCase() || "",
      version: cardData.version?.toLowerCase() || "",
      collector_number: cardData.collector_number?.toString() || "",
      type: cardData.type?.toLowerCase() || "",
      rarity: cardData.rarity?.toLowerCase() || "",
      text: cardData.text?.toLowerCase() || "",
      flavor_text: cardData.flavor_text?.toLowerCase() || "",
      ink: cardData.ink?.toLowerCase() || "",
    };
  };

  const fetchSearchResults = async (term) => {
    setLoading(true);
    setNoResults(false);
    try {
      const setsRef = collection(db, "sets");
      const setsSnapshot = await getDocs(setsRef);
      const results = [];
      const termLower = term.toLowerCase();

      // Parallel fetching for each set and its associated cards/listings
      await Promise.all(
        setsSnapshot.docs.map(async (setDoc) => {
          const setData = setDoc.data();
          const cardsSnapshot = await getDocs(collection(db, `sets/${setDoc.id}/cards`));

          const filteredCards = await Promise.all(
            cardsSnapshot.docs
              .filter((cardDoc) => {
                const cardData = flattenData(cardDoc.data());

                return (
                  cardData.name.includes(termLower) ||
                  cardData.version.includes(termLower) ||
                  cardData.collector_number.includes(termLower) ||
                  cardData.type.includes(termLower) ||
                  cardData.rarity.includes(termLower) ||
                  cardData.text.includes(termLower) ||
                  cardData.flavor_text.includes(termLower) ||
                  cardData.classifications.includes(termLower) ||
                  cardData.illustrators.includes(termLower) ||
                  cardData.ink.includes(termLower)
                );
              })
              .map(async (cardDoc) => {
                const cardData = cardDoc.data();
                // Fetch card data and listings in parallel
                const [listingsSnapshot] = await Promise.all([
                  getDocs(collection(db, `sets/${setDoc.id}/cards/${cardDoc.id}/listings`)),
                ]);

                const hasListings = listingsSnapshot.size > 0 ? 1 : 0;

                const resultCard = {
                  ...cardData,
                  setName: setData.name,
                  cardName: cardData.name,
                  collectorNumber: cardData.collector_number,
                  listingsCount: hasListings,
                  cardId: cardDoc.id,
                  setId: setDoc.id,
                  cardVersion: cardData.version || "",
                };

                return resultCard;
              })
          );

          results.push(...filteredCards);
        })
      );

      if (results.length === 0) {
        setNoResults(true);
      }

      setSearchResults(results);
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setLoading(false);
    }
  };

  // Debounce the search function
  const debouncedFetchSearchResults = useCallback(
    debounce((term) => {
      if (term) {
        fetchSearchResults(term);
      } else {
        setSearchResults([]);
        setNoResults(false);
      }
    }, 500),
    []
  );

  // Handle search input changes
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    debouncedFetchSearchResults(event.target.value);
  };

  // Handle when a user selects a search result
  const handleSelectResult = (event, value) => {
    if (value) {
      navigate(`/marketplace/${value.setId}/${value.cardId}`, {
        state: { selectedCard: value },
      });
    }
  };

  const formatRarity = (rarity) => {
    if (!rarity) return "";
    return rarity
      .toLowerCase()
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Autocomplete
        freeSolo
        options={searchResults}
        getOptionLabel={(option) =>
          `${option.setName}
          ${option.cardName}
          ${option.cardVersion}
          ${option.collectorNumber}
          ${option.rarity}
          ${option.type}
          ${option.classifications}`
        }
        loading={loading}
        onChange={handleSelectResult}
        noOptionsText={noResults ? "No results found" : "Start typing to search..."}
        renderOption={(props, option) => (
          <Box
            component="li"
            {...props}
            key={`${option.setId}-${option.cardId}`}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              backgroundColor: option.listingsCount ? "#B2E6B2" : "inherit",
              padding: "8px",
            }}
          >
            <img
              src={option.image_uris?.digital?.small}
              alt={option.cardName}
              style={{
                width: 50,
                height: 70,
                objectFit: "contain",
                border: "1px solid #ccc",
              }}
            />
            <Box>
              <Typography variant="body1">
                {`${option.cardName} - ${option.cardVersion} (#${option.collector_number})`}
              </Typography>
              <Typography variant="body2">
                {`${option.setName} - ${option.type} - ${formatRarity(option.rarity)}`}
              </Typography>
              <Typography variant="body2">
                {`Listings: ${option.listingsCount ? "Yes" : "No"}`}
              </Typography>
            </Box>
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            fullWidth
          />
        )}
      />
      {noResults && !loading && (
        <Typography variant="body2" color="textSecondary" sx={{ textAlign: 'center', marginTop: 2 }}>
          No results found.
        </Typography>
      )}
    </Box>
  );
};

export default SearchBar;
