import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { doc, getDoc, getDocs, collection, updateDoc, setDoc } from 'firebase/firestore';
import {
  Container,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  IconButton,
  DialogActions,

} from "@mui/material";
import { useAuth } from '../auth';
import SalesList from './SalesList';
import PaymentRequestDialog from './PaymentRequestDialog';
import CloseIcon from '@mui/icons-material/Close';
import PrepareShippingDialog from './PrepareShippingDialog';
import MarkAsShippedDialog from './MarkAsShippedDialog';
import PublicProfile from '../pages/PublicProfile'; 
import Loading from '../components/Loading';
import SellerDispute from './SellerDispute';
import MessageBox from '../components/MessageBox';
import PickingSlipDialog from './PickingSlipDalog';
import SendMessage from '../messages/SendMessage';

const SellerSales = () => {
  const { currentUser } = useAuth();
  const [sales, setSales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [paymentRequestDialogOpen, setPaymentRequestDialogOpen] = useState(false);
  const [prepareShippingDialogOpen, setPrepareShippingDialogOpen] = useState(false);
  const [markAsShippedDialogOpen, setMarkAsShippedDialogOpen] = useState(false);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [refundDialogOpen, setRefundDialogOpen] = useState(false);
  const [disputeDialogOpen, setDisputeDialogOpen] = useState(false);
  const [refundProcessedDialogOpen, setRefundProcessedDialogOpen] = useState(false);
  const [selectedSale, setSelectedSale] = useState(null);
  const [message, setMessage] = useState('');
  const [cancelReason, setCancelReason] = useState('');
  const [refundReason, setRefundReason] = useState('');
  const [refundPaymentDetails, setRefundPaymentDetails] = useState({});
  const [selectedPaymentOptions, setSelectedPaymentOptions] = useState([]);
  const [selectedPostageOptions, setSelectedPostageOptions] = useState([]);
  const [usernames, setUsernames] = useState({});
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [pickingSlipDialogOpen, setPickingSlipDialogOpen] = useState(false);
  const [recipientName, setRecipientName] = useState(''); 
  const [capturedImages, setCapturedImages] = useState([]);  // Add captured images state
  const [imageFiles, setImageFiles] = useState([]);  // Add image files state
  const [open, setOpen] = useState(false);
  const [sellerPreferences, setSellerPreferences] = useState({
    paymentOptions: {},
    postageOptions: [],
  });
  const [sellerDetails, setSellerDetails] = useState({});

  const [messageBoxOpen, setMessageBoxOpen] = useState(false);
  const [messageBoxContent, setMessageBoxContent] = useState({ title: '', message: '' });

  useEffect(() => {
    const fetchSales = async () => {
      if (currentUser) {
        const salesSnapshot = await getDocs(collection(db, 'users', currentUser.uid, 'sales'));
        const salesData = salesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setSales(salesData);
        setLoading(false);

        const buyerIds = [...new Set(salesData.map(sales => sales.buyerId))];
        const fetchedUsernames = {};
        for (const buyerId of buyerIds) {
          const buyerDoc = await getDoc(doc(db, 'users', buyerId));
          if (buyerDoc.exists()) {
            fetchedUsernames[buyerId] = buyerDoc.data().username;
          }
        }
        setUsernames(fetchedUsernames);
      }
    };

    fetchSales();
  }, [currentUser]);

  useEffect(() => {
    const fetchSellerPreferences = async () => {
      if (currentUser) {
        const docRef = doc(db, 'users', currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setSellerPreferences(docSnap.data().sellerPreferences || { paymentOptions: {}, postageOptions: [] });
        }
      }
    };

    fetchSellerPreferences();
  }, [currentUser]);

  const refreshSales = async () => {
    if (currentUser) {
      const salesSnapshot = await getDocs(collection(db, 'users', currentUser.uid, 'sales'));
      const salesData = salesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setSales(salesData);
    }
  };

  const createConversationId = (userId1, userId2) => {
    return [userId1, userId2].sort().join('_');
  };
  

  const handleOpen = (sellerId) => {
    setSelectedUserId(sellerId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedUserId(null);
  };

  const resetPaymentRequestDialog = () => {
    setMessage('');
    setSelectedPaymentOptions([]);
    setSelectedPostageOptions([]);
    setCapturedImages([]);  // Reset captured images
    setImageFiles([]);  // Reset image files
  };

  const handlePaymentDialogClose = () => {
    resetPaymentRequestDialog();
    setPaymentRequestDialogOpen(false);
  };

  const getTotalCards = (items = []) => {
    return items.reduce((total, item) => total + item.staticQuantity, 0);
  };

  const handleSave = async (updatedItem) => {
    if (selectedSale) {
      const saleRef = doc(db, 'users', currentUser.uid, 'sales', selectedSale.id);
      const purchaseRef = doc(db, 'users', selectedSale.buyerId, 'purchases', selectedSale.id);
  
      // Update the items in the sale
      const updatedItems = selectedSale.items.map((item) =>
        item.docName === updatedItem.docName ? updatedItem : item
      );
  
      // Calculate the new total by summing the total of all items
      const newTotal = updatedItems.reduce((acc, item) => acc + (item.quantity * item.price), 0);
  
      // Update the sale in Firestore
      await updateDoc(saleRef, {
        items: updatedItems,
        total: newTotal,  // Update the overall sale total
        status: 'Price/Quantity Modified',
      });
  
      // Update the corresponding purchase for the buyer
      await updateDoc(purchaseRef, {
        items: updatedItems,
        total: newTotal,  // Update the overall purchase total as well
        status: 'Price/Quantity Modified',
      });
  
      refreshSales();  // Refresh the sales after saving
    } else {
      console.error('No selected sale to update.');
    }
  };
  

  

  const handleRequestPayment = async (customPaymentMethod, customPaymentDetails) => {
    if (selectedSale) {
      const saleRef = doc(db, 'users', currentUser.uid, 'sales', selectedSale.id);
      const purchaseRef = doc(db, 'users', selectedSale.buyerId, 'purchases', selectedSale.id);
  
      const availableShippingOptions = selectedPostageOptions.map(method => {
        const option = sellerPreferences.postageOptions.find(opt => opt.method === method);
        return {
          method: option.method,
          chargePerItem: option.chargePerItem,
        };
      }).filter(option => option.method && option.chargePerItem);
  
      const availablePaymentOptions = selectedPaymentOptions.map(option => {
        if (option === 'bankDeposit') {
          return {
            method: option,
            details: {
              accountName: customPaymentDetails.accountName || sellerPreferences.paymentOptions.bankDeposit.accountName || '',
              accountNumber: customPaymentDetails.accountNumber || sellerPreferences.paymentOptions.bankDeposit.accountNumber || '',
              bsb: customPaymentDetails.bsb || sellerPreferences.paymentOptions.bankDeposit.bsb || '',
              fee: customPaymentDetails.fee || sellerPreferences.paymentOptions.bankDeposit.fee || 0,
              feeType: customPaymentDetails.feeType || sellerPreferences.paymentOptions.bankDeposit.feeType || '$'
            }
          };
        }
      
        if (option === 'paypal') {
          return {
            method: option,
            details: sellerPreferences.paymentOptions.paypal.paypalID || '',
            fee: sellerPreferences.paymentOptions.paypal.fee || 0,
            feeType: sellerPreferences.paymentOptions.paypal.feeType || '%'
          };
        }
      
        if (option === 'payID') {
          return {
            method: option,
            details: sellerPreferences.paymentOptions.payID.payID || '',
            fee: sellerPreferences.paymentOptions.payID.fee || 0,
            feeType: sellerPreferences.paymentOptions.payID.feeType || '$'
          };
        }
      
        // Handle custom or other payment methods
        if (option === 'other') {
          return {
            method: customPaymentMethod,
            details: customPaymentDetails,
            fee: customPaymentDetails.fee || 0,
            feeType: customPaymentDetails.feeType || '%',
          };
        }
      
        return null; // Return null if the option doesn't match
      }).filter(option => option); // Remove null entries
  
      await updateDoc(saleRef, {
        status: 'Payment Requested',
        paymentMessage: message,
        paymentOptions: availablePaymentOptions,
        availableShippingOptions,
        inventory: selectedSale.items,
        sellerDetails,  // Passing seller details here
      });
  
      await updateDoc(purchaseRef, {
        status: 'Payment Requested',
        paymentMessage: message,
        availableShippingOptions,
        paymentOptions: availablePaymentOptions,
        sellerDetails,  // Passing seller details here
      });
  
      setPaymentRequestDialogOpen(false);
      setMessageBoxContent({
        title: 'Payment Request Sent',
        message: 'The payment request has been successfully sent to the buyer.',
      });
      setMessageBoxOpen(true);
      refreshSales();
    }
  };

  const handleMarkPaid = async (saleId, buyerId) => {
    const saleRef = doc(db, 'users', currentUser.uid, 'sales', saleId);
    const purchaseRef = doc(db, 'users', buyerId, 'purchases', saleId);
  
    await updateDoc(saleRef, {
      status: 'Paid',
    });
  
    await updateDoc(purchaseRef, {
      status: 'Paid',
    });
  
    setMessageBoxContent({
      title: 'Status Updated',
      message: 'The status has been updated to Paid.',
    });
    setMessageBoxOpen(true);
    refreshSales();
  };
  

  const handleMarkPreparing = async (saleId, buyerId) => {
    const saleRef = doc(db, 'users', currentUser.uid, 'sales', saleId);
    const purchaseRef = doc(db, 'users', buyerId, 'purchases', saleId);
  
    await updateDoc(saleRef, {
      status: 'Preparing for Shipping',
    });
  
    await updateDoc(purchaseRef, {
      status: 'Preparing for Shipping',
    });
  
    setMessageBoxContent({
      title: 'Status Updated',
      message: 'The status has been updated to Preparing for Shipping.',
    });
    setMessageBoxOpen(true);
    setPrepareShippingDialogOpen(false);
    refreshSales();
  };
  

  const handleMarkShipped = async (trackingNumber, shippingCompany) => {
    if (selectedSale) {
      const saleRef = doc(db, 'users', currentUser.uid, 'sales', selectedSale.id);
      const purchaseRef = doc(db, 'users', selectedSale.buyerId, 'purchases', selectedSale.id);
  
      await updateDoc(saleRef, {
        status: 'Shipped',
        trackingNumber,
        shippingCompany,
      });
  
      await updateDoc(purchaseRef, {
        status: 'Shipped',
        trackingNumber,
        shippingCompany,
      });
  
      setMessageBoxContent({
        title: 'Status Updated',
        message: 'The status has been updated to Shipped.',
      });
      setMessageBoxOpen(true);
      setMarkAsShippedDialogOpen(false);
      refreshSales();
    }
  };
  

  const handleViewDispute = (sale) => {
    const saleWithSellerId = { ...sale, sellerId: currentUser.uid };
    setSelectedSale(saleWithSellerId);
    setDisputeDialogOpen(true);
  };
  

  const handleDisputeSubmit = async (resolutionData) => {
    if (selectedSale) {
      const saleRef = doc(db, 'users', currentUser.uid, 'sales', selectedSale.id);
      const purchaseRef = doc(db, 'users', selectedSale.buyerId, 'purchases', selectedSale.id);
  
      let newStatus = '';
      if (resolutionData.remediationAction === 'Refund') {
        newStatus = 'Refund Requested';
      } else if (resolutionData.remediationAction === 'Replace') {
        newStatus = 'Preparing for Shipping';
      } else if (resolutionData.resolutionAction === 'approve') {
        newStatus = 'Dispute Approved';
      } else {
        newStatus = 'Dispute Denied';
      }
  
      const resolutionUpdate = {
        status: newStatus,
        sellerResponse: resolutionData.sellerResponse || '',
      };
  
      await updateDoc(saleRef, resolutionUpdate);
      await updateDoc(purchaseRef, resolutionUpdate);
  
      setMessageBoxContent({
        title: 'Dispute Handled',
        message: `The dispute has been handled successfully. New status: ${newStatus}.`,
      });
      setMessageBoxOpen(true);
      setDisputeDialogOpen(false);
      refreshSales();
    }
  };
  

  const handleCancelSale = async () => {
    if (selectedSale) {
      const saleRef = doc(db, 'users', currentUser.uid, 'sales', selectedSale.id);
      const purchaseRef = doc(db, 'users', selectedSale.buyerId, 'purchases', selectedSale.id);
  
      // Restore item quantities
      for (const item of selectedSale.items) {
        const listingRef = doc(db, 'sets', item.setId, 'cards', item.docName, 'listings', item.listingId);
        const listingDoc = await getDoc(listingRef);
  
        if (listingDoc.exists()) {
          const listingData = listingDoc.data();
          const listingKey = Object.keys(listingData)[0]; // Assuming the listing data has one key
          const listingInfo = listingData[listingKey];
  
          const restoredQuantity = listingInfo.quantity + item.quantity;
          await setDoc(listingRef, {
            [listingKey]: {
              ...listingInfo,
              quantity: restoredQuantity,
            },
          }, { merge: true });
        }
      }
  
      // Update sale and purchase status
      await updateDoc(saleRef, {
        status: 'Cancelled',
        cancellationReason: cancelReason,
      });
  
      await updateDoc(purchaseRef, {
        status: 'Cancelled',
        cancellationReason: cancelReason,
      });
  
      setCancelDialogOpen(false);
      setCancelReason('');
      setMessageBoxContent({
        title: 'Sale Cancelled',
        message: 'The sale has been successfully cancelled.',
      });
      setMessageBoxOpen(true);
      refreshSales();
    }
  };
  

  const handleOpenCancelDialog = (sale) => {
    setSelectedSale(sale);
    setCancelDialogOpen(true);
  };

  const handleCloseCancelDialog = () => {
    setCancelDialogOpen(false);
    setCancelReason('');
  };

  const handleRefundRequest = (sale) => {
    setSelectedSale(sale);
    setRefundReason(sale.refundReason || '');
    setRefundPaymentDetails(sale.refundPaymentDetails || {});
    setRefundDialogOpen(true);
  };

  const handleApproveRefund = async () => {
    if (selectedSale) {
      const saleRef = doc(db, 'users', currentUser.uid, 'sales', selectedSale.id);
      const purchaseRef = doc(db, 'users', selectedSale.buyerId, 'purchases', selectedSale.id);
  
      await updateDoc(saleRef, { status: 'Refund Approved' });
      await updateDoc(purchaseRef, { status: 'Refund Approved' });
  
      setMessageBoxContent({
        title: 'Refund Approved',
        message: 'The refund has been successfully approved.',
      });
      setMessageBoxOpen(true);
      setRefundDialogOpen(false);
      setRefundProcessedDialogOpen(true);
      refreshSales();
    }
  };
  

  const handleDenyRefund = async () => {
    if (selectedSale) {
      const saleRef = doc(db, 'users', currentUser.uid, 'sales', selectedSale.id);
      const purchaseRef = doc(db, 'users', selectedSale.buyerId, 'purchases', selectedSale.id);
  
      await updateDoc(saleRef, { status: 'Refund Denied', message });
      await updateDoc(purchaseRef, { status: 'Refund Denied', message });
  
      setMessageBoxContent({
        title: 'Refund Denied',
        message: 'The refund has been denied successfully.',
      });
      setMessageBoxOpen(true);
      setRefundDialogOpen(false);
      refreshSales();
    }
  };
  

  const handleMarkRefundProcessed = async () => {
    if (selectedSale) {
      const saleRef = doc(db, 'users', currentUser.uid, 'sales', selectedSale.id);
      const purchaseRef = doc(db, 'users', selectedSale.buyerId, 'purchases', selectedSale.id);
  
      await updateDoc(saleRef, { status: 'Refund Processed' });
      await updateDoc(purchaseRef, { status: 'Refund Processed' });
  
      setMessageBoxContent({
        title: 'Refund Processed',
        message: 'The refund has been marked as processed successfully.',
      });
      setMessageBoxOpen(true);
      setRefundProcessedDialogOpen(false);
      refreshSales();
    }
  };
  

  const handlePaymentOptionChange = (option, isRemoving = false) => {
    setSelectedPaymentOptions(prev => {
      if (isRemoving) {
        return prev.filter(opt => opt !== option);
      }
      if (prev.includes(option)) {
        return prev.filter(opt => opt !== option);
      } else {
        return [...prev, option];
      }
    });
  };

  const handlePostageOptionChange = (method) => {
    setSelectedPostageOptions(prev => {
      if (prev.includes(method)) {
        return prev.filter(opt => opt !== method);
      } else {
        return [...prev, method];
      }
    });
  };

  const handleUpdateRefundStatus = (sale) => {
    setSelectedSale(sale);
    setRefundProcessedDialogOpen(true);
  };

  const handleMessageBuyer = (buyerId, buyerName) => {
    setSelectedUserId(buyerId);
    setRecipientName(buyerName);
    setOpen(true);
  };
  
  

  const renderRefundPaymentDetails = () => {
    const details = refundPaymentDetails || {};

    if (details.accountName && details.accountNumber && details.bsb) {
        return (
            <div>
                <Typography variant="subtitle2">Bank Transfer Details</Typography>
                <Typography variant="body2">Account Name: {details.accountName || 'N/A'}</Typography>
                <Typography variant="body2">Account Number: {details.accountNumber || 'N/A'}</Typography>
                <Typography variant="body2">BSB: {details.bsb || 'N/A'}</Typography>
            </div>
        );
    } else if (details.paypalID) {
        return (
            <div>
                <Typography variant="subtitle2">Paypal Details</Typography>
                <Typography variant="body2">Paypal ID: {details.paypalID || 'N/A'}</Typography>
            </div>
        );
    } else if (details.payID) {
        return (
            <div>
                <Typography variant="subtitle2">PayID Details</Typography>
                <Typography variant="body2">PayID: {details.payID || 'N/A'}</Typography>
            </div>
        );
    } else if (details.otherDetails) {
        return (
            <div>
                <Typography variant="subtitle2">Other Payment Details</Typography>
                <Typography variant="body2">{details.otherDetails || 'N/A'}</Typography>
            </div>
        );
    }

    return <Typography variant="body2">No payment details provided.</Typography>;
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        My Sales
      </Typography>
      <SalesList
        usernames={usernames}
        onOpenProfile={handleOpen}
        sales={sales}
        onSelectSale={setSelectedSale}
        onSetPaymentRequestDialogOpen={setPaymentRequestDialogOpen}
        onSetPrepareShippingDialogOpen={setPrepareShippingDialogOpen}
        onSetMarkAsShippedDialogOpen={setMarkAsShippedDialogOpen}
        onMarkPaid={handleMarkPaid}
        onCancelSale={handleOpenCancelDialog}
        onRefundRequest={handleRefundRequest}
        onViewDispute={handleViewDispute}
        onSave={handleSave}
        onUpdateRefundStatus={handleUpdateRefundStatus}
        onSetPickingSlipDialogOpen={setPickingSlipDialogOpen}
        getTotalCards={getTotalCards}
        onMessageBuyer={handleMessageBuyer}
        currentUser={currentUser}
      />
      <PickingSlipDialog
        open={pickingSlipDialogOpen}
        onClose={() => setPickingSlipDialogOpen(false)}
        selectedSale={selectedSale}
        getTotalCards={getTotalCards}
        usernames={usernames}
      />
      {sales.length === 0 && (
        <Typography variant="body1" align="center">
          No sales to show.
        </Typography>
      )}
      <PaymentRequestDialog
        open={paymentRequestDialogOpen}
        onClose={handlePaymentDialogClose}
        sale={{
          ...selectedSale,
          paymentOptions: sellerPreferences.paymentOptions,
        }}
        message={message}
        onMessageChange={setMessage}
        selectedPaymentOptions={selectedPaymentOptions}
        setSellerDetails={setSellerDetails}  // Pass the setter for seller details
        onPaymentOptionChange={handlePaymentOptionChange}
        postageOptions={sellerPreferences.postageOptions}
        selectedPostageOptions={selectedPostageOptions}
        onPostageOptionChange={handlePostageOptionChange}
        onRequestPayment={handleRequestPayment}
        saleId={selectedSale?.id} // Ensure saleId is passed correctly
        selectedSale={selectedSale} // Pass selectedSale as a prop
        currentUser={currentUser} // Pass currentUser as a prop
        capturedImages={capturedImages}
        setCapturedImages={setCapturedImages} // Pass the state and setter for images
        imageFiles={imageFiles}
        setImageFiles={setImageFiles}
      />

      <PrepareShippingDialog
        open={prepareShippingDialogOpen}
        onClose={() => setPrepareShippingDialogOpen(false)}
        sale={selectedSale}
        onMarkPreparing={handleMarkPreparing}
      />
      <MarkAsShippedDialog
        open={markAsShippedDialogOpen}
        onClose={() => setMarkAsShippedDialogOpen(false)}
        sale={selectedSale}
        onMarkShipped={handleMarkShipped}
      />
      <Dialog
        open={refundDialogOpen}
        onClose={() => setRefundDialogOpen(false)}
      >
        <DialogTitle>Handle Refund Request</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Please review the refund request details below.
          </Typography>
          <Typography variant="subtitle1">Reason for Refund</Typography>
          <Typography variant="body2">
            {refundReason || "No reason provided."}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Payment Details for Refund
          </Typography>
          {renderRefundPaymentDetails()}
          <TextField
            label="Message to Buyer"
            fullWidth
            multiline
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            variant="outlined"
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRefundDialogOpen(false)} color="secondary">
            Close
          </Button>
          <Button onClick={handleApproveRefund} color="primary">
            Approve Refund
          </Button>
          <Button onClick={handleDenyRefund} color="primary">
            Deny Refund
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={refundProcessedDialogOpen}
        onClose={() => setRefundProcessedDialogOpen(false)}
      >
        <DialogTitle>Refund Approved</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            The refund has been approved. Please process the refund and mark it
            as completed.
          </Typography>
          <Button
            onClick={() => setRefundProcessedDialogOpen(false)}
            color="secondary"
          >
            Close
          </Button>
          <Button onClick={handleMarkRefundProcessed} color="primary">
            Mark as Refund Processed
          </Button>
          <Button
            onClick={() => setRefundProcessedDialogOpen(true)}
            color="primary"
          >
            View Refund Payment Details
          </Button>
        </DialogContent>
        <DialogActions>{renderRefundPaymentDetails()}</DialogActions>
      </Dialog>
      <SellerDispute
        open={disputeDialogOpen}
        onClose={() => setDisputeDialogOpen(false)}
        onSubmit={handleDisputeSubmit}
        disputeDetails={selectedSale} // Pass the selected sale's dispute details
      />
      <Dialog open={cancelDialogOpen} onClose={handleCloseCancelDialog}>
        <DialogTitle>Cancel Sale</DialogTitle>
        <DialogContent>
          <TextField
            label="Reason for Cancellation"
            fullWidth
            multiline
            rows={4}
            value={cancelReason}
            onChange={(e) => setCancelReason(e.target.value)}
            variant="outlined"
            margin="normal"
          />
          <Button onClick={handleCloseCancelDialog} color="secondary">
            Close
          </Button>
          <Button onClick={handleCancelSale} color="primary">
            Confirm Cancellation
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {selectedUserId && <PublicProfile userId={selectedUserId} />}
        </DialogContent>
      </Dialog>

      <Dialog
        open={open}
        onClose={() => setOpen(false)} // Close the dialog
        maxWidth="md"
        fullWidth
        fullScreen
      >
        <DialogTitle>
          Send Message to {recipientName}
          
        </DialogTitle>
        <DialogContent>
          {/* Pass the recipientId, conversationId, and recipientName to the SendMessage component */}
          <SendMessage
            conversationId={createConversationId(
              currentUser.uid,
              selectedUserId
            )}
            recipientId={selectedUserId}
            recipientName={recipientName}
          />
        </DialogContent>
        <DialogActions>
        <Button
  variant="contained"
  color="secondary"
  onClick={() => setOpen(false)} 
  aria-label="close"
>
  Close
</Button>

        </DialogActions>
      </Dialog>

      <MessageBox
        open={messageBoxOpen}
        onClose={() => setMessageBoxOpen(false)}
        title={messageBoxContent.title}
        message={messageBoxContent.message}
      />
    </Container>
  );
};

export default SellerSales;
