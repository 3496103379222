import React, { useState } from 'react';
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Typography,
  Paper,
  Avatar,
  Box,
  Dialog,
  TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import './PickingSlipDialog.css'; 

// Utility function for sorting by collector number
const sortByCollectorNumber = (items) => {
  return [...items].sort((a, b) => a.collector_number - b.collector_number);
};

// Utility function for grouping by set and then by card type
const groupItemsBySetAndType = (items) => {
  const groupedItems = {};

  items.forEach((item) => {
    if (!groupedItems[item.setName]) {
      groupedItems[item.setName] = {};
    }
    if (!groupedItems[item.setName][item.cardType]) {
      groupedItems[item.setName][item.cardType] = [];
    }
    groupedItems[item.setName][item.cardType].push(item);
  });

  return groupedItems;
};

const SaleItemsTable = ({ sale, showPickingColors = false, showImages = true, onSave, isSeller = false }) => {
  const groupedItems = groupItemsBySetAndType(sale.items || []);
  const [selectedImage, setSelectedImage] = useState(null); // State to store the clicked image
  const [selectedItems, setSelectedItems] = useState([]);
  const [editableItem, setEditableItem] = useState(null);
  const [editedQuantity, setEditedQuantity] = useState(0);
  const [editedPrice, setEditedPrice] = useState(0);
  const [reasonDialogOpen, setReasonDialogOpen] = useState(false);
  const [selectedReason, setSelectedReason] = useState('');

  const reasons = ['Incorrect Listing Qty', 'Wrong Listing Price', 'Discount Offer', 'Other'];

  const handleEdit = (item) => {
    setEditableItem(item.docName); // Start editing this item
    setEditedQuantity(item.staticQuantity);
    setEditedPrice(item.staticPrice);
  };

  const handleSaveEdit = () => {
    // Open the reason selection dialog when saving changes
    setReasonDialogOpen(true);
  };

  const handleSaveWithReason = () => {
    const updatedItem = {
      ...sale.items.find((i) => i.docName === editableItem),
      staticQuantity: editedQuantity,
      staticPrice: editedPrice,
      quantity: editedQuantity,  // Update the quantity
      price: editedPrice,        // Update the price
      reasonForChange: selectedReason,  // Save the selected reason
    };

    onSave(updatedItem);  // Call the onSave function passed as a prop
    setEditableItem(null);  // Stop editing
    setReasonDialogOpen(false);  // Close the reason dialog
  };

  // Function to close the reason dialog without saving
  const handleCancelReason = () => {
    setReasonDialogOpen(false);
    setSelectedReason('');
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handleCloseDialog = () => {
    setSelectedImage(null);
  };

  const handleRowClick = (item) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.includes(item.docName)
        ? prevSelectedItems.filter((selected) => selected !== item.docName)
        : [...prevSelectedItems, item.docName]
    );
  };
  

  return (
    <>
      {showPickingColors && (
        <Box
          className="picking-colors"
          display="flex"
          justifyContent="center"
          gap="1rem"
          mb={2}
        >
          <Typography variant="body1">
            <Box
              component="span"
              sx={{
                display: "inline-block",
                width: "20px",
                height: "20px",
                backgroundColor: "#d4f8d4",
                mr: 1,
              }}
            />
            Picked
          </Typography>
          <Typography variant="body1">
            <Box
              component="span"
              sx={{
                display: "inline-block",
                width: "20px",
                height: "20px",
                backgroundColor: "#ff9696",
                mr: 1,
              }}
            />
            Not Picked
          </Typography>
        </Box>
      )}
      <TableContainer component={Paper} sx={{ overflowX: "auto" }}>
        <Table
          sx={{
            "& .MuiTableCell-root": {
              padding: { xs: "4px", sm: "8px" },
              fontSize: { xs: "0.75rem", sm: "0.875rem" }, // Adjust text size for mobile
            },
          }}
        >
          {/* Iterate through grouped items by Set Name and Card Type */}
          {Object.keys(groupedItems).map((setName) => (
            <React.Fragment key={setName}>
              {/* Set Name as Subheader */}
              <TableRow>
                <TableCell colSpan={4}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      fontSize: { xs: "0.875rem", sm: "1.5rem" },
                    }}
                  >
                    {setName}
                  </Typography>
                </TableCell>
              </TableRow>

              {Object.keys(groupedItems[setName]).map((cardType) => (
                <React.Fragment key={cardType}>
                  {/* Card Type as Subheader */}
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: { xs: "0.75rem", sm: "1.2rem" },
                          pl: 2,
                        }}
                      >
                        Card Type: {cardType}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  {/* Repeated Column Headers under each Set Name and Card Type */}
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{ width: { xs: "auto", sm: "40%" }, maxWidth: "40%" }}
                    >
                      Item
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ width: { xs: "auto", sm: "20%" } }}
                    >
                      Qty
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ width: { xs: "auto", sm: "20%" } }}
                    >
                      Each
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ width: { xs: "auto", sm: "20%" } }}
                    >
                      Total
                    </TableCell>
                  </TableRow>

                  {/* Sorted Items by Collector Number */}
                  {sortByCollectorNumber(groupedItems[setName][cardType]).map(
                    (item) => (
                      <TableRow
                        key={item.docName}
                        onClick={() => handleRowClick(item)}
                        sx={{
                          cursor: showPickingColors ? "pointer" : "default",
                          backgroundColor: showPickingColors
                            ? selectedItems.includes(item.docName)
                              ? "#d4f8d4"
                              : "#ff9696"
                            : "transparent",
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            width: { xs: "50%", sm: "40%" },
                            maxWidth: "50%",
                          }}
                        >
                          <Box display="flex" alignItems="center">
                            {/* Thumbnail with click event */}
                            {showImages && (
                              <Avatar
                                variant="square"
                                src={item.cardImage || "fallback_image_url"}
                                sx={{
                                  height: "80px",
                                  width: "60px",
                                  objectFit: "contain",
                                  marginRight: 2,
                                  borderRadius: 1,
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent row click event
                                  handleImageClick(item.cardImage); // Handle image preview
                                }}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = "fallback_image_url";
                                }}
                              />
                            )}
                            <Box>
                              <Typography
                                variant="body1"
                                sx={{
                                  fontSize: { xs: "0.75rem", sm: "1.2rem" },
                                }}
                              >
                                #{item.collector_number} - {item.cardName}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                sx={{
                                  fontSize: { xs: "0.65rem", sm: "1.2rem" },
                                }}
                              >
                                {item.cardRarity
                                  .toLowerCase()
                                  .split("_")
                                  .map(
                                    (word) =>
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1)
                                  )
                                  .join(" ")}
                              </Typography>
                              {item.reasonForChange && (
                                <Typography
                                  variant="caption"
                                  sx={{ color: "red" }}
                                >
                                  Item Modification Reason: {item.reasonForChange}
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        </TableCell>

                        <TableCell
                          align="right"
                          sx={{ width: { xs: "10%", sm: "20%" } }}
                        >
                          {editableItem === item.docName ? (
                            <TextField
                              type="number"
                              value={editedQuantity}
                              onChange={(e) =>
                                setEditedQuantity(Number(e.target.value))
                              }
                            />
                          ) : (
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              sx={{ fontSize: { xs: "0.75rem", sm: "1.2rem" } }}
                            >
                              {item.staticQuantity}
                            </Typography>
                          )}
                        </TableCell>

                        <TableCell
                          align="right"
                          sx={{ width: { xs: "20%", sm: "20%" } }}
                        >
                          {editableItem === item.docName ? (
                            <TextField
                              type="number"
                              value={editedPrice}
                              onChange={(e) =>
                                setEditedPrice(Number(e.target.value))
                              }
                            />
                          ) : (
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              sx={{ fontSize: { xs: "0.75rem", sm: "1.2rem" } }}
                            >
                              $
                              {typeof item.staticPrice === "number"
                                ? item.staticPrice.toFixed(2)
                                : item.staticPrice}
                            </Typography>
                          )}
                        </TableCell>

                        <TableCell
                          align="right"
                          sx={{ width: { xs: "20%", sm: "20%" } }}
                        >
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{ fontSize: { xs: "0.75rem", sm: "1.2rem" } }}
                          >
                            $
                            {(item.staticPrice * item.staticQuantity).toFixed(
                              2
                            )}
                          </Typography>
                        </TableCell>
                        {isSeller &&
                        <TableCell align="right">
                          {editableItem === item.docName ? (
                            <Button onClick={handleSaveEdit}>Save</Button>
                          ) : (
                            <Button onClick={() => handleEdit(item)}>
                              Edit
                            </Button>
                          )}
                        </TableCell>
                        }
                      </TableRow>
                    )
                  )}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </Table>
      </TableContainer>

      {/* Reason selection dialog */}
      <Dialog open={reasonDialogOpen} onClose={handleCancelReason}>
        <Box padding={2}>
          <Typography variant="h6">Select Reason for Change</Typography>
          <FormControl fullWidth margin="normal">
            <InputLabel id="reason-label">Reason</InputLabel>
            <Select
              labelId="reason-label"
              value={selectedReason}
              onChange={(e) => setSelectedReason(e.target.value)}
            >
              {reasons.map((reason) => (
                <MenuItem key={reason} value={reason}>
                  {reason}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box display="flex" justifyContent="flex-end" marginTop={2}>
            <Button onClick={handleCancelReason} color="secondary">
              Cancel
            </Button>
            <Button
              onClick={handleSaveWithReason}
              color="primary"
              variant="contained"
            >
              Save
            </Button>
          </Box>
        </Box>
      </Dialog>

      {/* Larger image preview in a dialog */}
      {selectedImage && (
        <Dialog
          open={!!selectedImage}
          onClose={handleCloseDialog}
          maxWidth="md"
        >
          <Box sx={{ padding: 2 }}>
            <img
              src={selectedImage}
              alt="Card"
              style={{ width: "100%", height: "auto", objectFit: "contain" }}
            />
          </Box>
        </Dialog>
      )}
    </>
  );
};

export default SaleItemsTable;
