import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Box,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  MenuItem,
} from '@mui/material';
import { inkColorMap } from '../marketplace/constants';

const WishlistFacebookListingCreator = ({ wishlistItems, convertPrice, selectedItems, setSelectedItems, currentUser }) => {
  const [selectedSet, setSelectedSet] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState('collector_number');
  const [cardPrices, setCardPrices] = useState({}); // Store custom prices or TCGP usage for each card

  const toggleSelectItem = (card) => {
    const itemId = card.docId;
    setSelectedItems((prevSelected) => {
      if (prevSelected.some((item) => item.itemId === itemId)) {
        return prevSelected.filter((item) => item.itemId !== itemId);
      } else {
        return [...prevSelected, { card, itemId }];
      }
    });
  };

  const handleSetChange = (event) => {
    setSelectedSet(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSortChange = (event) => {
    setSortField(event.target.value);
  };

  const sortCards = (cards) => {
    switch (sortField) {
      case 'collector_number':
        return cards.sort((a, b) => parseInt(a.collector_number, 10) - parseInt(b.collector_number, 10));
      case 'name':
        return cards.sort((a, b) => a.name.localeCompare(b.name));
      case 'set':
        return cards.sort((a, b) => a.setName.localeCompare(b.setName));
      default:
        return cards;
    }
  };

  const filteredCards = wishlistItems.filter((card) => {
    const matchesSet = selectedSet ? card.setName === selectedSet : true;
    const matchesSearchTerm = searchTerm ? card.name.toLowerCase().includes(searchTerm.toLowerCase()) : true;
    return matchesSet && matchesSearchTerm;
  });

  const sortedFilteredCards = sortCards(filteredCards);

  const groupCardsBySet = (cards) => {
    return cards.reduce((acc, card) => {
      if (!acc[card.setName]) {
        acc[card.setName] = [];
      }
      acc[card.setName].push(card);
      return acc;
    }, {});
  };

  const handlePriceChange = (cardId, useTCGPPrice, customPrice = null) => {
    setCardPrices((prevPrices) => ({
      ...prevPrices,
      [cardId]: { useTCGPPrice, customPrice },
    }));
  };

  const handleSelectAll = () => {
    const allItems = wishlistItems.map((card) => ({
      card,
      itemId: card.docId,
    }));
    setSelectedItems(allItems);
  };

  const handleSelectNone = () => {
    setSelectedItems([]);
  };

  const handleUseTCGPPriceForAll = () => {
    const updatedPrices = {};
    wishlistItems.forEach((card) => {
      updatedPrices[card.docId] = { useTCGPPrice: true, customPrice: cardPrices[card.docId]?.customPrice || '' };
    });
    setCardPrices(updatedPrices);
  };

  const handleDeselectTCGPPriceForAll = () => {
    const updatedPrices = {};
    wishlistItems.forEach((card) => {
      updatedPrices[card.docId] = { useTCGPPrice: false, customPrice: cardPrices[card.docId]?.customPrice || '' };
    });
    setCardPrices(updatedPrices);
  };

  const groupedCards = groupCardsBySet(sortedFilteredCards);

  const generateFacebookPost = () => {
    const padRight = (str, length) => str.padEnd(length);
    const collectorNumberLength = 6;
    const nameLength = 20;
    const priceLength = 10;
    const typeLength = 10;
  
    const selectedGroupedCards = groupCardsBySet(selectedItems.map(item => item.card)); // Filter only selected cards
  
    const facebookPost = Object.entries(selectedGroupedCards)
      .map(([setName, cards]) => {
        const setHeader = `${setName}\n`;
        const cardsList = cards.map((card) => {
          const collectorNumber = padRight(`#${card.collector_number}`, collectorNumberLength);
          const name = padRight(card.name, nameLength);
          const cardType = padRight(card.type, typeLength);
          const priceDetails = cardPrices[card.docId];
          const price = priceDetails?.useTCGPPrice
            ? padRight(`${convertPrice(card.price || 0)}ea`, priceLength)
            : padRight(`${convertPrice(1).split(' ')[0]} ${priceDetails?.customPrice || 0}ea`, priceLength);
  
          return `${collectorNumber} ${name} ${cardType} ${price}`; // Include card type in the return
        }).join('\n');
  
        return `${setHeader}${cardsList}`;
      }).join('\n\n');
  
    return `${facebookPost}`;
  };

  return (
    <Box sx={{ pt: 2 }}>
      {/* Filters */}
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <TextField
          select
          label="Filter by Set"
          value={selectedSet}
          onChange={handleSetChange}
          fullWidth
        >
          <MenuItem value="">
            <em>All Sets</em>
          </MenuItem>
          {[...new Set(wishlistItems.map(card => card.setName))].map(setName => (
            <MenuItem key={setName} value={setName}>
              {setName}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          label="Search"
          value={searchTerm}
          onChange={handleSearchChange}
          fullWidth
        />

        <TextField
          select
          label="Sort By"
          value={sortField}
          onChange={handleSortChange}
          fullWidth
        >
          <MenuItem value="collector_number">Card Number</MenuItem>
          <MenuItem value="name">Card Name</MenuItem>
          <MenuItem value="set">Set Name</MenuItem>
        </TextField>
      </Box>

      {/* Select All and Select None Buttons */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2, mb: 2 }}>
        <Box>
          <Button variant="contained" onClick={handleSelectAll}>
            Select All
          </Button>
          <Button variant="contained" onClick={handleSelectNone} sx={{ ml: 2 }}>
            Select None
          </Button>
        </Box>
        <Box>
          <Button variant="contained" onClick={handleUseTCGPPriceForAll}>
            Use TCGP Price for All
          </Button>
          <Button variant="contained" onClick={handleDeselectTCGPPriceForAll} sx={{ ml: 2 }}>
            Deselect TCGP Price for All
          </Button>
        </Box>
      </Box>

      {/* Card List */}
      <List>
        {Object.entries(groupedCards).map(([setName, cards]) => (
          <Box key={setName} mb={2}>
            <Typography variant="h6">{setName}</Typography>
            {cards.map((card) => {
              const itemId = card.docId;
              const isSelected = selectedItems.some((item) => item.itemId === itemId);
              const cardPrice = cardPrices[card.docId] || { useTCGPPrice: false, customPrice: '' };

              return (
                <ListItem key={itemId} sx={{ border: "2px solid #0C0A5A" }}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={2}>
                      <ListItemAvatar sx={{ width: 100, position: "relative" }}>
                        <Avatar
                          className={
                            card.type && card.type.toLowerCase() === "foil"
                              ? "foil-avatar"
                              : ""
                          }
                          variant="square"
                          src={card.image_uris?.digital?.small || card.image_uris?.digital?.large}
                          alt={card.name}
                          sx={{
                            height: "20%",
                            width: "80%",
                            objectFit: "contain",
                            border: `4px solid ${inkColorMap[card.ink] || "#F2D18B"}`,
                          }}
                        />
                        {/* Show "COLD FOIL" Avatar if the card type is "Foil" */}
                        {card.type && card.type.toLowerCase() === "foil" && (
                          <Avatar
                            variant="square"
                            sx={{
                              width: 76,
                              height: 10,
                              bgcolor: "#ffd700",
                              border: "2px solid #000000",
                              color: "#000000",
                              bottom: 18,
                              left: 4,
                            }}
                          >
                            <Typography variant="h6" sx={{ fontSize: "10px" }}>
                              COLD FOIL
                            </Typography>
                          </Avatar>
                        )}
                      </ListItemAvatar>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <ListItemText
                        primary={
                          <Typography variant="body1">
                            #{card.collector_number} - {card.name}
                          </Typography>
                        }
                        secondary={
                          <Typography variant="body2" color="textSecondary">
                            {card.setName}
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cardPrice.useTCGPPrice}
                            onChange={() =>
                              handlePriceChange(
                                card.docId,
                                !cardPrice.useTCGPPrice,
                                cardPrice.customPrice
                              )
                            }
                          />
                        }
                        label="Use TCGP Price"
                      />
                      {!cardPrice.useTCGPPrice && (
                        <TextField
                          label="Price you will pay each"
                          type="number"
                          value={cardPrice.customPrice}
                          onChange={(e) =>
                            handlePriceChange(card.docId, false, e.target.value)
                          }
                          fullWidth
                          margin="normal"
                          helperText={`${convertPrice(1).split(" ")[0]}`} // Display currency
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isSelected}
                            onChange={() => toggleSelectItem(card)}
                          />
                        }
                        label="Select"
                      />
                    </Grid>
                  </Grid>
                </ListItem>
              );
            })}
          </Box>
        ))}
      </List>

      {/* Facebook Post Generation */}
      {selectedItems.length > 0 && (
        <Box mt={4}>
          <Typography variant="h6">Post Preview:</Typography>
          <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
            {generateFacebookPost()}
          </Typography>
          <Button
            variant="contained"
            onClick={() => navigator.clipboard.writeText(generateFacebookPost())}
            sx={{ mt: 2 }}
          >
            Copy Post to Clipboard
            </Button>
        </Box>
      )}
    </Box>
  );
};

export default WishlistFacebookListingCreator;
