import React, { useState, useEffect, useReducer, useRef } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Avatar,
  Menu,
  Button,
  Tooltip,
  Link,
  Box,
  Badge,
  MenuItem,
  Divider,
  Paper,
  Popper,
  ClickAwayListener,
  useMediaQuery,
  useTheme,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from "@mui/material";
import CompactLanguagePicker from './CompactLanguagePicker';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import StorefrontIcon from '@mui/icons-material/Storefront';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import MessageIcon from '@mui/icons-material/Message';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SearchBar from './SearchBar';
import { useAppBarContext } from '../components/AppBarContext';
import { useAuth } from '../auth';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDocs, collection, onSnapshot, query, where, setDoc } from 'firebase/firestore';
import '../App.css'; // Import the CSS file

const initialState = {
  anchorEl: null,
  mobileMenuAnchorEl: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_ANCHOR_EL':
      return { ...state, anchorEl: action.payload };
    case 'RESET_ANCHOR_EL':
      return { ...state, anchorEl: null };
    case 'TOGGLE_MENU':
      return { ...state, mobileMenuAnchorEl: action.payload };
    default:
      return state;
  }
};

const AppHeader = ({ handleSearchResult }) => {
  const { selectedCountry, setSelectedCountry } = useAppBarContext();
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [cartCount, setCartCount] = useState(0);
  const [salesCount, setSalesCount] = useState(0);
  const [purchasesCount, setPurchasesCount] = useState(0);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const [searchOpen, setSearchOpen] = useState(false); // State to manage popper visibility
  const anchorRef = useRef(null); // Ref for the anchor element for the popper
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (currentUser) {
      // Fetch unread notifications
      const notificationsRef = collection(db, `users/${currentUser.uid}/notifications`);
      const q = query(notificationsRef, where('read', '==', false));

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const newNotifications = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setNotifications(newNotifications);
        setUnreadNotificationCount(newNotifications.length);
      });

      return () => unsubscribe();
    }
  }, [currentUser]);

  const handleNotificationClick = (event) => {
    setNotificationAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setNotificationAnchorEl(null);
  };

  const markNotificationAsRead = async (notificationId) => {
    const notificationRef = doc(db, `users/${currentUser.uid}/notifications`, notificationId);
    await setDoc(notificationRef, { read: true }, { merge: true });
  };

  const handleNotificationSelect = (notification) => {
    markNotificationAsRead(notification.id); // Mark the notification as read
    handleNotificationClose();
  };

  const open = Boolean(notificationAnchorEl);

  useEffect(() => {
    if (currentUser && currentUser.currency) {
      setSelectedCountry(currentUser.currency);
    }
  }, [currentUser, setSelectedCountry]);

  useEffect(() => {
    let unsubscribe;
    if (currentUser) {
      const cartRef = doc(db, 'carts', currentUser.uid);
      unsubscribe = onSnapshot(cartRef, (cartDoc) => {
        if (cartDoc.exists()) {
          const items = cartDoc.data().items;
          const count = items.reduce((sum, item) => sum + item.quantity, 0);
          setCartCount(count);
        }
      });
    }
    return () => unsubscribe && unsubscribe();
  }, [currentUser]);

  useEffect(() => {
    const fetchCounts = async () => {
      if (currentUser) {
        // Fetch Sales Count
        const salesSnapshot = await getDocs(collection(db, 'users', currentUser.uid, 'sales'));
        const salesData = salesSnapshot.docs.map(doc => doc.data());
        const pendingSales = salesData.filter(sale => sale.status === 'Pending' || sale.status === 'Paid' || sale.status === 'Preparing for Shipping' || sale.status === 'Buyer Approved').length;
        setSalesCount(pendingSales);

        // Fetch Purchases Count
        const purchasesSnapshot = await getDocs(collection(db, 'users', currentUser.uid, 'purchases'));
        const purchasesData = purchasesSnapshot.docs.map(doc => doc.data());
        const pendingPurchases = purchasesData.filter(purchase => purchase.status === 'Payment Requested' || purchase.status === 'Price/Quantity Modified' || purchase.status === 'Invoice Generated' || purchase.status === 'Received').length;
        setPurchasesCount(pendingPurchases);
      }
    };
    fetchCounts();
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      const messagesRef = collection(db, 'conversations');
      const q = query(
        messagesRef,
        where('participants', 'array-contains', currentUser.uid)
      );

      const unsubscribe = onSnapshot(q, async (snapshot) => {
        let unreadCount = 0;

        await Promise.all(
          snapshot.docs.map(async (conversationDoc) => {
            const conversationId = conversationDoc.id;

            const messagesQuery = query(
              collection(db, `conversations/${conversationId}/messages`),
              where('recipientId', '==', currentUser.uid),
              where('read', '==', false)
            );

            const messagesSnapshot = await getDocs(messagesQuery);
            unreadCount += messagesSnapshot.size;
          })
        );

        setUnreadMessagesCount(unreadCount);
      });

      return () => unsubscribe();
    }
  }, [currentUser]);

  const handleAvatarClick = (event) => {
    dispatch({ type: 'SET_ANCHOR_EL', payload: event.currentTarget });
  };

  const handleMenuClose = () => {
    dispatch({ type: 'RESET_ANCHOR_EL' });
  };

  const handleProfile = () => {
    navigate('/profile');
    handleMenuClose();
  };

  const handleLogout = async () => {
    await logout();
    navigate('/login');
    handleMenuClose();
  };

  const handleNavigation = (path) => {
    navigate(path);
    handleMenuClose();
    handleMobileMenuClose();  // Close the mobile menu after navigation
  };

  const handleSearchClick = () => {
    setSearchOpen((prev) => !prev); // Toggle the search popper
  };

  const handleCloseSearch = () => {
    setSearchOpen(false); // Close the popper when clicking outside
  };

  const handleMobileMenuClick = (event) => {
    dispatch({ type: 'TOGGLE_MENU', payload: event.currentTarget });
  };

  const handleMobileMenuClose = () => {
    dispatch({ type: 'TOGGLE_MENU', payload: null });
  };

  return (
    <AppBar position="fixed" sx={{ bgcolor: "#0C0A5A" }} className="app-bar">
      <Toolbar className="toolbar">
        <Box
          className="left-section"
          sx={{
            gap: 1,
          }}
        >
          {/* Menu and Logo */}

          <IconButton
            color="inherit"
            aria-label="menu"
            onClick={handleMobileMenuClick}
            sx={{
              border: "2px solid #FFFFFF",
              borderRadius: "50%",
              padding: "8px",
              boxSizing: "border-box",
            }}
          >
            <Badge
              badgeContent={salesCount + purchasesCount + unreadMessagesCount}
              color="secondary"
              overlap="circular"
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuIcon />
            </Badge>
          </IconButton>

          <Avatar
            variant="square"
            alt="Lorcana Card Marketplace Logo"
            src="/logo192.png"
            sx={{ width: 40, height: 40, marginLeft: 1 }}
          />
          <Typography
            variant="h6"
            sx={{ textAlign: "center", display: { xs: "none", sm: "block" } }}
          >
            Lorcana Card Marketplace
          </Typography>
        </Box>
        <Box className="right-section">
          {currentUser ? (
            <>
              <Tooltip title="Search">
                <IconButton
                  color="inherit"
                  aria-label="search"
                  ref={anchorRef}
                  onClick={handleSearchClick}
                >
                  <SearchIcon />
                </IconButton>
              </Tooltip>
              <Popper
                open={searchOpen}
                anchorEl={anchorRef.current}
                placement="bottom"
                style={{ zIndex: 1300 }}
                disablePortal
                modifiers={[
                  {
                    name: "offset",
                    options: {
                      offset: isMobile ? [0, 16] : [0, 0],
                    },
                  },
                ]}
              >
                <ClickAwayListener onClickAway={handleCloseSearch}>
                  <Paper
                    elevation={3}
                    sx={{
                      width: { xs: "100vw", sm: "100vw" },
                      maxWidth: "100%",
                      margin: "0 auto",
                      left: 0,
                      right: 0,
                      position: "relative",
                    }}
                  >
                    {/* SearchBar Component */}
                    <SearchBar onSelectSearchResult={handleSearchResult} />
                  </Paper>
                </ClickAwayListener>
              </Popper>
              {/* Notifications */}
              <Tooltip title="Notifications">
                <IconButton
                  color="inherit"
                  aria-label="notifications"
                  onClick={handleNotificationClick}
                >
                  <Badge badgeContent={unreadNotificationCount} color="error">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
              {/* Notification Popper */}
              <Popper
                open={open}
                anchorEl={notificationAnchorEl}
                placement="bottom"
                style={{ zIndex: 1300 }}
              >
                <ClickAwayListener onClickAway={handleNotificationClose}>
                  <Paper elevation={3} sx={{ width: 300 }}>
                    <List>
                      {notifications.length === 0 ? (
                        <ListItem>
                          <ListItemText primary="No new notifications" />
                        </ListItem>
                      ) : (
                        // Sort notifications by timestamp in ascending order (oldest first)
                        notifications
                          .sort(
                            (a, b) =>
                              a.timestamp.toDate() - b.timestamp.toDate()
                          )
                          .map((notification) => (
                            <ListItem
                              key={notification.id}
                              button
                              onClick={() =>
                                handleNotificationSelect(notification)
                              }
                            >
                              <ListItemText
                                primary={notification.message}
                                secondary={new Date(
                                  notification.timestamp?.toDate()
                                ).toLocaleString()}
                              />
                            </ListItem>
                          ))
                      )}
                    </List>
                  </Paper>
                </ClickAwayListener>
              </Popper>
              {!isMobile && (
                <>
                  <Tooltip title="My Sales">
                    <IconButton
                      color="inherit"
                      aria-label="sales"
                      onClick={() => handleNavigation("/sales")}
                    >
                      <Badge badgeContent={salesCount} color="secondary">
                        <MonetizationOnOutlinedIcon />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="My Purchases">
                    <IconButton
                      color="inherit"
                      aria-label="purchases"
                      onClick={() => handleNavigation("/purchases")}
                    >
                      <Badge badgeContent={purchasesCount} color="secondary">
                        <ShoppingBagOutlinedIcon />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                </>
              )}
              <Tooltip title="Messages">
                <IconButton
                  color="inherit"
                  aria-label="messages"
                  onClick={() => handleNavigation("/messages")}
                >
                  <Badge badgeContent={unreadMessagesCount} color="error">
                    <MessageIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
              <Box sx={{ mx: 1 }} /> {/* Space between My Purchases and Cart */}
              <CompactLanguagePicker
                selectedCountry={selectedCountry}
                setSelectedCountry={setSelectedCountry}
              />
              <Tooltip title="Cart">
                <IconButton
                  color="inherit"
                  aria-label="cart"
                  onClick={() => handleNavigation("/cart")}
                >
                  <Badge badgeContent={cartCount} color="secondary">
                    <ShoppingCartIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
              <IconButton
                onClick={handleAvatarClick}
                sx={{ display: { xs: "none", sm: "block" } }} // Hide Avatar on mobile
              >
                <Avatar
                  alt={currentUser.firstName}
                  src={currentUser.photoURL || currentUser.firstName}
                />
              </IconButton>
              <Menu
                anchorEl={state.anchorEl}
                open={Boolean(state.anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleProfile}>My Profile</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </>
          ) : (
            <Button
              variant="contained"
              color="secondary"
              component={Link}
              onClick={handleLogout}
            >
              Login
            </Button>
          )}
        </Box>

        <Menu
          anchorEl={state.mobileMenuAnchorEl}
          open={Boolean(state.mobileMenuAnchorEl)}
          onClose={handleMobileMenuClose}
        >
          <MenuItem onClick={() => handleNavigation("/")}>
            <HomeIcon sx={{ marginRight: 1 }} /> Home
          </MenuItem>
          <MenuItem onClick={() => handleNavigation("/marketplace")}>
            <StorefrontIcon sx={{ marginRight: 1 }} /> Marketplace
          </MenuItem>
          <MenuItem onClick={() => handleNavigation("/inventory-management")}>
            <Inventory2OutlinedIcon sx={{ marginRight: 1 }} /> My Inventory
          </MenuItem>
          {/*
          <MenuItem onClick={() => handleNavigation("/bundle-management")} disabled>
            <DynamicFeedIcon sx={{ marginRight: 1 }} /> My Sale Bundles
          </MenuItem>
          */}
          <MenuItem onClick={() => handleNavigation("/sales")}>
            <Badge
              badgeContent={salesCount}
              color="secondary"
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MonetizationOnOutlinedIcon />
            </Badge>
            <Typography sx={{ marginLeft: 1 }}>My Sales</Typography>
          </MenuItem>

          <MenuItem onClick={() => handleNavigation("/purchases")}>
            <Badge
              badgeContent={purchasesCount}
              color="secondary"
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <ShoppingBagOutlinedIcon />
            </Badge>
            <Typography sx={{ marginLeft: 1 }}>My Purchases</Typography>
          </MenuItem>

          <MenuItem onClick={() => handleNavigation("/mycollection")}>
            <FactCheckOutlinedIcon sx={{ marginRight: 1 }} /> My Collection
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => handleNavigation("/messages")}>
            <Badge
              badgeContent={unreadMessagesCount}
              color="error"
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MessageIcon />
            </Badge>
            <Typography sx={{ marginLeft: 1 }}>Messages</Typography>
          </MenuItem>
          <MenuItem onClick={() => handleNavigation("/profile")}>
            {currentUser && (
              <Box
                sx={{
                  display: { xs: "flex", sm: "flex" },
                  alignItems: "center",
                  flexDirection: "row",
                  gap: 1,
                }}
              >
                <Avatar
                  alt={currentUser.firstName}
                  src={currentUser.photoURL || currentUser.firstName}
                  sx={{ width: 40, height: 40 }}
                />
                <Typography variant="subtitle1" sx={{ mt: 1 }}>
                  {currentUser.firstName}
                </Typography>
              </Box>
            )}
          </MenuItem>

          <MenuItem
            onClick={handleLogout}
            sx={{ justifyContent: "center", color: "red" }}
          >
            <Typography variant="body2">LOGOUT</Typography>
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default AppHeader;
