import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions, // Added for actions in the dialog
  useMediaQuery,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { collection, getDocs, doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../auth';
import { useAppBarContext } from '../components/AppBarContext';
import Loading from '../components/Loading';
import WishlistCardList from './WishlistCardList';
import WishlistFacebookListingCreator from './WishlistFacebookListingCreator';
import WishlistMarketplaceListings from './WishlistMarketplaceListings';
import { rarities, inkColors, cardTypes, inkColorMap } from '../marketplace/constants';

const WishList = () => {
  const { currentUser } = useAuth();
  const { selectedCountry, exchangeRates } = useAppBarContext();
  const [wishlistItems, setWishlistItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [openListingsDialog, setOpenListingsDialog] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false); // Confirmation dialog state
  const [selectedItems, setSelectedItems] = useState([]);
  const [filterRarity, setFilterRarity] = useState('');
  const [filterInk, setFilterInk] = useState('');
  const [filterCardType, setFilterCardType] = useState('');
  const [filterSet, setFilterSet] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('collector_number');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchWishlist = async () => {
      setLoading(true);
      try {
        const wishlist = [];
        const collectionPath = `users/${currentUser.uid}/mycollection`;
        const setsSnapshot = await getDocs(collection(db, collectionPath));

        for (let setDoc of setsSnapshot.docs) {
          const setId = setDoc.id;
          const cardsSnapshot = await getDocs(collection(db, collectionPath, setId, 'cards'));
          cardsSnapshot.docs.forEach((cardDoc) => {
            const cardData = cardDoc.data();
            if (cardData.inWishlist) {
              wishlist.push({
                docId: cardDoc.id,
                setId: setId,
                ...cardData,
              });
            }
          });
        }

        setWishlistItems(wishlist);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching wishlist items:', error);
        setLoading(false);
      }
    };

    if (currentUser) {
      fetchWishlist();
    }
  }, [currentUser]);

  const handleAddToList = async (card, listType) => {
    try {
      const collectionPath = `users/${currentUser.uid}/mycollection/${card.setId}/cards`;
      const cardRef = doc(db, collectionPath, card.docId);
      const updatedCardData = { ...card, inWishlist: listType === 'wishlist' ? !card.inWishlist : card.inWishlist };

      await setDoc(cardRef, updatedCardData);
      setWishlistItems((prevItems) => prevItems.filter((item) => item.docId !== card.docId));
    } catch (error) {
      console.error('Error handling list update:', error);
    }
  };

  const convertPrice = (usdPrice) => {
    if (!exchangeRates[selectedCountry]) return usdPrice;
    return `${selectedCountry} ${(usdPrice * exchangeRates[selectedCountry]).toFixed(2)}`;
  };

  const sortedAndFilteredWishlist = wishlistItems
    .filter((item) => (filterRarity ? item.rarity === filterRarity : true))
    .filter((item) => (filterInk ? item.ink === filterInk : true))
    .filter((item) => (filterCardType ? item.type === filterCardType : true))
    .filter((item) => (filterSet ? item.setName === filterSet : true))
    .filter((item) => (searchTerm ? item.name.toLowerCase().includes(searchTerm.toLowerCase()) : true))
    .sort((a, b) => {
      if (sortOrder === 'collector_number') {
        return parseInt(a.collector_number, 10) - parseInt(b.collector_number, 10);
      } else if (sortOrder === 'name') {
        return a.name.localeCompare(b.name);
      } else if (sortOrder === 'set') {
        return a.setName.localeCompare(b.setName);
      } else {
        return 0;
      }
    });

  // Calculate the total TCGP price of the filtered wishlist
  const totalTCGPPrice = sortedAndFilteredWishlist.reduce((total, item) => total + (item.price || 0), 0);

  const handleOpenDialog = () => {
    setOpenConfirmationDialog(true); // Show confirmation dialog before opening Facebook post dialog
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenListingsDialog = () => {
    setOpenListingsDialog(true);
  };

  const handleCloseListingsDialog = () => {
    setOpenListingsDialog(false);
  };

  const handleContinueToFacebookPost = () => {
    setOpenConfirmationDialog(false);
    setOpenDialog(true);
  };

  const handleGoBackToFilter = () => {
    setOpenConfirmationDialog(false);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Container maxWidth={isMobile ? "xs" : "md"}>
      <Box
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        justifyContent={isMobile ? "center" : "space-between"}
        alignItems={isMobile ? "stretch" : "center"}
        mb={2}
      >
        <Box
          display="flex"
          flexDirection={isMobile ? "column" : "row"}
          gap={isMobile ? 2 : 0}
          alignItems="center"
        >
          <Button
            variant="contained"
            color="primary"
            fullWidth={isMobile}
            onClick={handleOpenDialog}
          >
            Create Facebook Post
          </Button>
          <Button
            variant="contained"
            color="secondary"
            fullWidth={isMobile}
            onClick={handleOpenListingsDialog}
            sx={{ ml: isMobile ? 0 : 2, mt: isMobile ? 1 : 0 }}
          >
            Search Marketplace Listings
          </Button>
        </Box>
      </Box>

      {/* Confirmation Dialog */}
      <Dialog
        open={openConfirmationDialog}
        onClose={handleGoBackToFilter}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Confirm Post Creation</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            Make sure you have filtered your wishlist to only the cards you want to include in the post!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleGoBackToFilter} color="secondary">
            Go Back and Filter
          </Button>
          <Button onClick={handleContinueToFacebookPost} color="primary">
            Continue
          </Button>
        </DialogActions>
      </Dialog>

      {/* Accordion for Filters on Mobile */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="filters-content"
          id="filters-header"
        >
          <Typography variant="h6">Filters</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {/* Filter by Rarity */}
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Filter by Rarity</InputLabel>
                <Select
                  value={filterRarity}
                  onChange={(e) => setFilterRarity(e.target.value)}
                  label="Filter by Rarity"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {rarities.map((rarity) => (
                    <MenuItem key={rarity} value={rarity}>
                      {rarity}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Filter by Ink */}
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Filter by Ink Color</InputLabel>
                <Select
                  value={filterInk}
                  onChange={(e) => setFilterInk(e.target.value)}
                  label="Filter by Ink Color"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {inkColors.map((ink) => (
                    <MenuItem key={ink} value={ink}>
                      <Box display="flex" alignItems="center">
                        <Box
                          width={15}
                          height={15}
                          bgcolor={inkColorMap[ink]}
                          borderRadius="50%"
                          mr={1}
                        />
                        {ink}
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Filter by Card Type */}
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Filter by Card Type</InputLabel>
                <Select
                  value={filterCardType}
                  onChange={(e) => setFilterCardType(e.target.value)}
                  label="Filter by Card Type"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {cardTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Filter by Set */}
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Filter by Set</InputLabel>
                <Select
                  value={filterSet}
                  onChange={(e) => setFilterSet(e.target.value)}
                  label="Filter by Set"
                >
                  <MenuItem value="">
                    <em>All Sets</em>
                  </MenuItem>
                  {[...new Set(wishlistItems.map(item => item.setName))].map(setName => (
                    <MenuItem key={setName} value={setName}>
                      {setName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Search by Card Name */}
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                label="Search by Card Name"
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Grid>

            {/* Sort by */}
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Sort by</InputLabel>
                <Select
                  value={sortOrder}
                  onChange={(e) => setSortOrder(e.target.value)}
                  label="Sort by"
                >
                  <MenuItem value="collector_number">Card Number</MenuItem>
                  <MenuItem value="name">Card Name</MenuItem>
                  <MenuItem value="set">Set Name</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      {/* Total TCGP Price */}
      <Box mt={3} mb={2}>
        <Typography variant="h6">
          Total TCGP Price for visible cards: {convertPrice(totalTCGPPrice)}
        </Typography>
      </Box>

      {/* Wishlist Items */}
      {sortedAndFilteredWishlist.length > 0 ? (
        <WishlistCardList
          items={sortedAndFilteredWishlist}
          convertPrice={convertPrice}
          handleAddToList={handleAddToList}
        />
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={isMobile ? "50vh" : "100vh"}
        >
          <Typography variant="h6">Your wishlist is empty.</Typography>
        </Box>
      )}

      {/* Facebook Listing Creator Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Create Facebook Post from Wishlist</DialogTitle>
        <DialogContent>
          <WishlistFacebookListingCreator
            wishlistItems={sortedAndFilteredWishlist}
            currentUser={currentUser}
            convertPrice={convertPrice}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
          />
        </DialogContent>
      </Dialog>

      {/* Marketplace Listings Dialog */}
      <Dialog
        open={openListingsDialog}
        onClose={handleCloseListingsDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          <WishlistMarketplaceListings
            wishlistItems={wishlistItems}
            convertPrice={convertPrice}
          />
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default WishList;
