import React, { useEffect, useState } from 'react';
import { storage, db } from '../firebase';
import { doc, getDocs, getDoc, collection, updateDoc, setDoc, deleteDoc } from 'firebase/firestore';
import { ref, deleteObject } from 'firebase/storage';
import {
  Link, List, ListItem, ListItemText, MenuItem, Divider, ListSubheader, ListItemAvatar, Avatar, Button, Typography, Container, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Box, Grid, IconButton, Radio, RadioGroup, FormControl, FormControlLabel, useMediaQuery, useTheme 
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useAuth } from '../auth';
import SendIcon from '@mui/icons-material/Send';
import Invoice from './Invoice';
import RateSellerDialog from './RateSellerDialog';
import PublicProfile from '../pages/PublicProfile';
import Loading from '../components/Loading';
import BuyerDispute from './BuyerDispute';
import PaymentRequestedDialog from './PaymentRequestedDialog';
import MessageBox from '../components/MessageBox';
import ImageViewerDialog from './ImageViewerDialog';
import SearchSortToolbar from './SearchSortToolbar';
import PurchaseList from './PurchaseList';  

const BuyerPurchases = () => {
  const { currentUser } = useAuth();
  const [purchases, setPurchases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('date'); 
  const [collapsedSections, setCollapsedSections] = useState({});
  const [allCollapsed, setAllCollapsed] = useState(false); 
  const [selectedPurchase, setSelectedPurchase] = useState(null);
  const [message, setMessage] = useState('');
  const [refundReason, setRefundReason] = useState('');
  const [refundPaymentMethod, setRefundPaymentMethod] = useState('');
  const [refundPaymentDetails, setRefundPaymentDetails] = useState({});
  const [selectedShippingOption, setSelectedShippingOption] = useState('');
  const [shippingCost, setShippingCost] = useState(0);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState('');
  const [paymentDetails, setPaymentDetails] = useState('');
  const [buyerAddress, setBuyerAddress] = useState({});
  const [usernames, setUsernames] = useState({});
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [cancelReason, setCancelReason] = useState('');
  const [open, setOpen] = useState(false);
  const [totalCost, setTotalCost] = useState(0);
  const [paymentFee, setPaymentFee] = useState(0);
  const [viewDisputeDialogOpen, setViewDisputeDialogOpen] = useState(false);
  const [messageBoxOpen, setMessageBoxOpen] = useState(false);
  const [markAsPaidDialogOpen, setMarkAsPaidDialogOpen] = useState(false);
  const [buyerPaymentMessage, setBuyerPaymentMessage] = useState('');
  const [messageBoxContent, setMessageBoxContent] = useState({ title: '', message: '' });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [putBackInCartDialogOpen, setPutBackInCartDialogOpen] = useState(false);
  const [refundDialogOpen, setRefundDialogOpen] = useState(false);
  const [disputeDialogOpen, setDisputeDialogOpen] = useState(false);
  const [trackingDialogOpen, setTrackingDialogOpen] = useState(false);
  const [approveChangesDialogOpen, setApproveChangesDialogOpen] = useState(false);
  const [invoiceDialogOpen, setInvoiceDialogOpen] = useState(false);
  const [rateSellerDialogOpen, setRateSellerDialogOpen] = useState(false);
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const [imageDialogOpen, setImageDialogOpen] = useState(false);

  const handleOpenCancelDialog = (purchase) => {
    setSelectedPurchase(purchase);
    setCancelDialogOpen(true);
  };

  const handleOpenPutBackInCartDialog = (purchase) => {
    setSelectedPurchase(purchase);
    setPutBackInCartDialogOpen(true);
  };
  
  const handleOpenRefundDialog = (purchase) => {
    setSelectedPurchase(purchase);
    setRefundDialogOpen(true);
  };
  
  const handleOpenDisputeDialog = (purchase) => {
    setSelectedPurchase(purchase);
    setDisputeDialogOpen(true);
  };
  
  const handleOpenInvoiceDialog = (purchase) => {
    setSelectedPurchase(purchase);
    setInvoiceDialogOpen(true);
  };
  
  const handleOpenTrackingDialog = (purchase) => {
    setSelectedPurchase(purchase);
    setTrackingDialogOpen(true);
  };

  const handleOpenApproveChangesDialog = (purchase) => {
    setSelectedPurchase(purchase);
    setApproveChangesDialogOpen(true);
  };
  
  const handleOpenPaymentDialog = (purchase) => {
    setSelectedPurchase(purchase);
    setPaymentDialogOpen(true);
  };

  const handleOpenProfile = (sellerId) => {
    setSelectedUserId(sellerId);
    setOpen(true);
  };

  const handlePutBackInCart = async () => {
    try {

        if (!selectedPurchase.items || !Array.isArray(selectedPurchase.items)) {
            console.error('Invalid purchase items:', selectedPurchase.items);
            throw new Error('No items found in purchase or items is not an array');
        }

        const cartRef = doc(db, 'carts', currentUser.uid);
        const cartDoc = await getDoc(cartRef);

        let cartItems = [];
        if (cartDoc.exists()) {
            cartItems = cartDoc.data().items || [];
        }

        const updatedCartItems = [...cartItems];

        selectedPurchase.items.forEach(purchaseItem => {
            // Only include the necessary fields for the cart in the correct format
            const cartItem = {
                docName: purchaseItem.docName,
                setId: purchaseItem.setId,
                quantity: purchaseItem.quantity,
                price: purchaseItem.price,
                image_uris: { digital: { small: purchaseItem.cardImage } },
                name: purchaseItem.cardName,
                rarity: purchaseItem.cardRarity,
                ink: purchaseItem.cardInk,
                collector_number: purchaseItem.collector_number,
                listingId: purchaseItem.listingId,
                sellerId: purchaseItem.listingId, // Correct sellerId
                type: 'listing', // Make sure this is defined correctly
                condition: purchaseItem.condition || 'Near Mint',
            };


            const existingItemIndex = updatedCartItems.findIndex(
                item => item.docName === purchaseItem.docName && item.setId === purchaseItem.setId
            );

            if (existingItemIndex >= 0) {
                updatedCartItems[existingItemIndex].quantity += purchaseItem.quantity;
            } else {
                updatedCartItems.push(cartItem);
            }
        });


        // Add the items to the cart in Firestore
        await setDoc(cartRef, { items: updatedCartItems });
        
        // Remove purchase from buyer's purchases collection
        const buyerPurchaseRef = doc(db, 'users', currentUser.uid, 'purchases', selectedPurchase.id);
        await deleteDoc(buyerPurchaseRef);

        // Remove sale from seller's sales collection
        const sellerSaleRef = doc(db, 'users', selectedPurchase.sellerId, 'sales', selectedPurchase.id);
        await deleteDoc(sellerSaleRef);

        setPutBackInCartDialogOpen(false);
        fetchPurchases();

        alert('Items have been successfully moved back to your cart.');
    } catch (error) {
        console.error('Error moving items to cart:', error);
        alert('There was an error moving items back to your cart.');
    }
};



  useEffect(() => {
    fetchPurchases();
  }, [currentUser]);

  useEffect(() => {
    if (selectedShippingOption && selectedPurchase) {
      const shippingOption = selectedPurchase.availableShippingOptions?.find(option => option.method === selectedShippingOption);
      if (shippingOption) {
        setShippingCost(parseFloat(shippingOption.chargePerItem.replace(/[^0-9.-]+/g, "")));
      } else {
        setShippingCost(0);
      }
    }
  }, [selectedShippingOption, selectedPurchase]);

  const handleOpenImageViewer = (purchase) => {
    setSelectedPurchase(purchase);
    setImageDialogOpen(true);
  };

  const handleCloseImageViewer = () => {
    setImageDialogOpen(false);
  };

  useEffect(() => {
    if (selectedPurchase) {
      const subtotal = selectedPurchase.total + shippingCost;

      const optionDetails = selectedPurchase?.paymentOptions?.find(
        (option) => option.method === selectedPaymentOption
      );

      if (optionDetails && optionDetails.details) {
        const feeType = optionDetails.feeType || '%';
        const feeValue = parseFloat(optionDetails.fee || 0);

        let calculatedFee = 0;
        if (feeType === '%') {
          calculatedFee = (feeValue / 100) * subtotal;
        } else {
          calculatedFee = feeValue;
        }

        setPaymentFee(calculatedFee);
        setTotalCost(subtotal + calculatedFee);
      } else {
        setPaymentFee(0);
        setTotalCost(subtotal);
      }
    }
  }, [shippingCost, selectedPurchase, selectedPaymentOption]);

  const groupPurchasesByDate = (purchases) => {
    return purchases.reduce((groups, purchase) => {
      const date = purchase.timestamp?.toDate()
        ? purchase.timestamp.toDate().toLocaleDateString()
        : new Date(purchase.timestamp).toLocaleDateString();
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(purchase);
      return groups;
    }, {});
  };

  const sortPurchases = (purchases, sortOption) => {
    return purchases.sort((a, b) => {
      if (sortOption === 'date') {
        return new Date(b.timestamp) - new Date(a.timestamp);
      } else if (sortOption === 'total') {
        return b.total - a.total;
      }
      return 0;
    });
  };

  const filteredPurchases = purchases.filter((purchase) => {
    const sellerName = usernames[purchase.sellerId] || purchase.sellerId;
    const searchInItems = purchase.items?.some((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
      )
    );

    return (
      sellerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      purchase.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
      String(purchase.total).includes(searchTerm) || // Allow search by total
      searchInItems
    );
  });

  const sortedPurchases = sortPurchases(filteredPurchases, sortOption);
  const groupedPurchases = groupPurchasesByDate(sortedPurchases);

  const toggleCollapse = (date) => {
    setCollapsedSections((prev) => ({
      ...prev,
      [date]: !prev[date],
    }));
  };

  const toggleAllSections = (collapse) => {
    const newState = Object.keys(groupedPurchases).reduce((acc, date) => {
      acc[date] = collapse;
      return acc;
    }, {});
    setCollapsedSections(newState);
    setAllCollapsed(collapse);
  };
  
  

  const fetchPurchases = async () => {
    const purchasesSnapshot = await getDocs(collection(db, 'users', currentUser.uid, 'purchases'));
    const purchasesData = purchasesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

    const sellerIds = [...new Set(purchasesData.map((purchase) => purchase.sellerId))];
    const fetchedUsernames = {};

    for (const sellerId of sellerIds) {
      const sellerDoc = await getDoc(doc(db, 'users', sellerId));
      if (sellerDoc.exists()) {
        fetchedUsernames[sellerId] = sellerDoc.data().username;
      }
    }

    setUsernames(fetchedUsernames);
    setPurchases(purchasesData);
    setLoading(false);
  };

  const fetchBuyerPreferences = async () => {
    const buyerDocRef = doc(db, 'users', currentUser.uid);
    const buyerDocSnap = await getDoc(buyerDocRef);

    if (buyerDocSnap.exists()) {
      return buyerDocSnap.data().buyerPreferences;
    } else {
      console.error('Buyer preferences not found');
      return null;
    }
  };

  const handleCancelPurchase = async () => {
    if (selectedPurchase) {
      const purchaseRef = doc(db, 'users', currentUser.uid, 'purchases', selectedPurchase.id);
      const saleRef = doc(db, 'users', selectedPurchase.sellerId, 'sales', selectedPurchase.id);
  
      for (const item of selectedPurchase.items) {
        const listingRef = doc(db, 'sets', item.setId, 'cards', item.docName, 'listings', item.listingId);
        const listingDoc = await getDoc(listingRef);
  
        if (listingDoc.exists()) {
          const listingData = listingDoc.data();
          const listingKey = Object.keys(listingData)[0];
          const listingInfo = listingData[listingKey];
  
          const restoredQuantity = listingInfo.quantity + item.quantity;
          await updateDoc(listingRef, {
            [listingKey]: {
              ...listingInfo,
              quantity: restoredQuantity,
            },
          });
        }
      }
  
      await updateDoc(purchaseRef, {
        status: 'Cancelled',
        cancellationReason: cancelReason,
      });
  
      await updateDoc(saleRef, {
        status: 'Cancelled',
        cancellationReason: cancelReason,
      });
  
      setCancelDialogOpen(false);
      setCancelReason('');
      setMessageBoxContent({
        title: 'Purchase Cancelled',
        message: 'The purchase has been successfully cancelled.',
      });
      setMessageBoxOpen(true);
      fetchPurchases();
    }
  };
  

  const handleRequestDispute = async (disputeData) => {
    if (selectedPurchase) {
      const purchaseRef = doc(db, 'users', currentUser.uid, 'purchases', selectedPurchase.id);
      const saleRef = doc(db, 'users', selectedPurchase.sellerId, 'sales', selectedPurchase.id);
  
      const updatedDisputeData = {
        ...disputeData,
        status: 'Dispute Requested',
      };
  
      await updateDoc(purchaseRef, updatedDisputeData);
      await updateDoc(saleRef, updatedDisputeData);
  
      setMessageBoxContent({
        title: 'Dispute Requested',
        message: 'The dispute has been successfully requested.',
      });
      setMessageBoxOpen(true);
      fetchPurchases();
    }
  };

  const handleCloseCancelDialog = () => {
    setCancelDialogOpen(false);
    setCancelReason('');
  };

  const handleClosePutBackInCartDialog = () => {
    setPutBackInCartDialogOpen(false);
    setCancelReason('');
  };

  const handleRequestRefund = async () => {
    if (selectedPurchase) {
      const purchaseRef = doc(db, 'users', currentUser.uid, 'purchases', selectedPurchase.id);
      const saleRef = doc(db, 'users', selectedPurchase.sellerId, 'sales', selectedPurchase.id);
  
      await updateDoc(purchaseRef, {
        status: 'Refund Requested',
        refundReason,
        refundPaymentMethod,
        refundPaymentDetails,
      });
  
      await updateDoc(saleRef, {
        status: 'Refund Requested',
        refundReason,
        refundPaymentMethod,
        refundPaymentDetails,
      });
  
      setRefundDialogOpen(false);
      setRefundReason('');
      setRefundPaymentDetails({});
      setMessageBoxContent({
        title: 'Refund Requested',
        message: 'The refund request has been successfully submitted.',
      });
      setMessageBoxOpen(true);
      fetchPurchases();
    }
  };

  const handleCloseRefundDialog = () => {
    setRefundDialogOpen(false);
    setRefundReason('');
    setRefundPaymentDetails({});
  };

  const handleOpen = (sellerId) => {
    setSelectedUserId(sellerId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedUserId(null);
  };

  const handleGetInvoice = async () => {
    const transactionDate = new Date(); 
    if (selectedPurchase) {
      const buyerPreferences = await fetchBuyerPreferences();

      if (buyerPreferences) {
        const purchaseRef = doc(db, 'users', currentUser.uid, 'purchases', selectedPurchase.id);
        const saleRef = doc(db, 'users', selectedPurchase.sellerId, 'sales', selectedPurchase.id);

        const sellerDoc = await getDoc(doc(db, 'users', selectedPurchase.sellerId));
        const sellerDetails = sellerDoc.exists() ? sellerDoc.data() : {};

        const updatedPurchaseData = {
          status: 'Invoice Generated',
          buyerPaymentMessage: message,
          buyerAddress: buyerPreferences,
          shippingCost,
          shippingOption: selectedShippingOption,
          paymentOption: { method: selectedPaymentOption, details: paymentDetails },
          totalCost,
          paymentFee,
          transactionDate,
          sellerDetails: {
            firstName: sellerDetails.firstName || 'Seller',
            lastName: sellerDetails.lastName || '',
            email: sellerDetails.email || '',
            phoneNumber: sellerDetails.phoneNumber || ''
          }
        };

        await updateDoc(purchaseRef, updatedPurchaseData);
        await updateDoc(saleRef, updatedPurchaseData);

        await fetchPurchases();

        setSelectedPurchase((prev) => ({ ...prev, ...updatedPurchaseData }));

        setPaymentDialogOpen(false);
        setInvoiceDialogOpen(true);
      } else {
        setMessageBoxContent({
          title: 'Attention Required',
          message: 'Failed to retrieve buyer preferences, please set your preferences in the Profile page.',
        });
        setMessageBoxOpen(true);
      }
    }
  };

  const handleViewDispute = (purchase) => {
    setSelectedPurchase(purchase);
    setViewDisputeDialogOpen(true);
  };

  const handleEscalateDispute = async () => {
    if (selectedPurchase) {
      const purchaseRef = doc(db, 'users', currentUser.uid, 'purchases', selectedPurchase.id);
      const saleRef = doc(db, 'users', selectedPurchase.sellerId, 'sales', selectedPurchase.id);
  
      await updateDoc(purchaseRef, { status: 'Dispute Escalated' });
      await updateDoc(saleRef, { status: 'Dispute Escalated' });
  
      setMessageBoxContent({
        title: 'Dispute Escalated',
        message: 'The dispute has been escalated to support.',
      });
      setMessageBoxOpen(true);
      setViewDisputeDialogOpen(false);
      fetchPurchases();
    }
  };
  
  
  const handleAcceptResolution = async () => {
    if (selectedPurchase) {
      const purchaseRef = doc(db, 'users', currentUser.uid, 'purchases', selectedPurchase.id);
      const saleRef = doc(db, 'users', selectedPurchase.sellerId, 'sales', selectedPurchase.id);
  
      await updateDoc(purchaseRef, { status: 'Received' });
      await updateDoc(saleRef, { status: 'Received' });
  
      setMessageBoxContent({
        title: 'Resolution Accepted',
        message: 'The dispute has been resolved. Please rate the seller to complete the transaction.',
      });
      setMessageBoxOpen(true);
      setViewDisputeDialogOpen(false);
      fetchPurchases();
    }
  };
  

  const handleMarkPaid = (purchase) => {
    setSelectedPurchase(purchase);
    setMarkAsPaidDialogOpen(true);
  };
  
  
  const confirmMarkAsPaid = async (purchaseId, sellerId) => {
    const purchaseRef = doc(db, 'users', currentUser.uid, 'purchases', purchaseId);
    const saleRef = doc(db, 'users', sellerId, 'sales', purchaseId);
  
    await updateDoc(purchaseRef, { status: 'Paid', buyerPaymentMessage });
    await updateDoc(saleRef, { status: 'Paid', buyerPaymentMessage });
  
    setMessageBoxContent({
      title: 'Status Updated',
      message: 'The status has been updated to Paid, and the message has been sent to the seller.',
    });
    setMessageBoxOpen(true);
    fetchPurchases();
  };
  
  

  const handleMarkReceived = async (purchaseId, sellerId) => {
    try {
      const purchaseRef = doc(db, 'users', currentUser.uid, 'purchases', purchaseId);
      const saleRef = doc(db, 'users', sellerId, 'sales', purchaseId);
  
      await updateDoc(purchaseRef, { status: 'Received' });
      await updateDoc(saleRef, { status: 'Received' });
  
      setMessageBoxContent({
        title: 'Status Updated',
        message: 'The status has been updated to Received.',
      });
      setMessageBoxOpen(true);
      
      // Close the tracking dialog after updating the status
      setTrackingDialogOpen(false);
  
      fetchPurchases();
    } catch (error) {
      console.error("Error marking purchase as received: ", error);
    }
  };

  const handleApproveChanges = async (purchaseId, sellerId) => {
    try {
      const purchaseRef = doc(db, 'users', currentUser.uid, 'purchases', purchaseId);
      const saleRef = doc(db, 'users', sellerId, 'sales', purchaseId);
  
      await updateDoc(purchaseRef, { status: 'Buyer Approved' });
      await updateDoc(saleRef, { status: 'Buyer Approved' });
  
      setMessageBoxContent({
        title: 'Status Updated',
        message: 'The status has been updated to Buyer Approved.',
      });
      setMessageBoxOpen(true);
      
      // Close the tracking dialog after updating the status
      setApproveChangesDialogOpen(false);
  
      fetchPurchases();
    } catch (error) {
      console.error("Error marking purchase as changes approved: ", error);
    }
  };
  
  
  

  const handleMarkRated = async (purchaseId, sellerId) => {
    const purchaseRef = doc(db, 'users', currentUser.uid, 'purchases', purchaseId);
    const saleRef = doc(db, 'users', sellerId, 'sales', purchaseId);
  
    // Delete images upon sale completion
    const saleDoc = await getDoc(saleRef);
    if (saleDoc.exists()) {
      const saleData = saleDoc.data();
      const imageUrls = saleData.imageUrls || [];
      for (const url of imageUrls) {
        const fileRef = ref(storage, url);
        await deleteObject(fileRef);
      }
      await updateDoc(saleRef, { imageUrls: [] });
    }
  
    await updateDoc(purchaseRef, { status: 'Completed' });
    await updateDoc(saleRef, { status: 'Completed' });
  
    setMessageBoxContent({
      title: 'Status Updated',
      message: 'The status has been updated to Completed.',
    });
    setMessageBoxOpen(true);
    fetchPurchases();
    setRateSellerDialogOpen(false);
  };
  
  

  const renderRefundPaymentDetailsFields = () => {
    switch (refundPaymentMethod) {
      case 'Bank Transfer':
        return (
          <Box>
            <TextField
              label="Account Name"
              fullWidth
              value={refundPaymentDetails.accountName || ''}
              onChange={(e) =>
                setRefundPaymentDetails((prev) => ({
                  ...prev,
                  accountName: e.target.value,
                }))
              }
              variant="outlined"
              margin="normal"
            />
            <TextField
              label="Account Number"
              fullWidth
              value={refundPaymentDetails.accountNumber || ''}
              onChange={(e) =>
                setRefundPaymentDetails((prev) => ({
                  ...prev,
                  accountNumber: e.target.value,
                }))
              }
              variant="outlined"
              margin="normal"
            />
            <TextField
              label="BSB"
              fullWidth
              value={refundPaymentDetails.bsb || ''}
              onChange={(e) =>
                setRefundPaymentDetails((prev) => ({
                  ...prev,
                  bsb: e.target.value,
                }))
              }
              variant="outlined"
              margin="normal"
            />
          </Box>
        );
      case 'Paypal':
        return (
          <TextField
            label="Paypal ID"
            fullWidth
            value={refundPaymentDetails.paypalID || ''}
            onChange={(e) =>
              setRefundPaymentDetails((prev) => ({
                ...prev,
                paypalID: e.target.value,
              }))
            }
            variant="outlined"
            margin="normal"
          />
        );
      case 'PayID':
        return (
          <TextField
            label="PayID"
            fullWidth
            value={refundPaymentDetails.payID || ''}
            onChange={(e) =>
              setRefundPaymentDetails((prev) => ({
                ...prev,
                payID: e.target.value,
              }))
            }
            variant="outlined"
            margin="normal"
          />
        );
      case 'Other':
        return (
          <TextField
            label="Other Payment Details"
            fullWidth
            multiline
            rows={4}
            value={refundPaymentDetails.otherDetails || ''}
            onChange={(e) =>
              setRefundPaymentDetails((prev) => ({
                ...prev,
                otherDetails: e.target.value,
              }))
            }
            variant="outlined"
            margin="normal"
          />
        );
      default:
        return null;
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        My Purchases
      </Typography>

      <PurchaseList
        purchases={purchases}
        usernames={usernames}
        searchTerm={searchTerm}
        sortOption={sortOption}
        onSearchChange={setSearchTerm}
        onSortChange={setSortOption}
        allCollapsed={allCollapsed}
        onToggleCollapse={setAllCollapsed}
        onOpenProfile={handleOpenProfile}
        onOpenCancelDialog={handleOpenCancelDialog}
        onOpenRefundDialog={handleOpenRefundDialog}
        onOpenDisputeDialog={handleOpenDisputeDialog}
        onOpenInvoiceDialog={handleOpenInvoiceDialog}
        onOpenTrackingDialog={handleOpenTrackingDialog}
        onOpenApproveChangesDialog={handleOpenApproveChangesDialog}
        onOpenPaymentDialog={handleOpenPaymentDialog}
        onOpenImageViewer={handleOpenImageViewer}
        onMarkAsPaid={handleMarkPaid}
        onRateSeller={() => setRateSellerDialogOpen(true)}
        onPutBackInCart={handleOpenPutBackInCartDialog}
      />

      {/* Cancel Purchase Dialog */}
      <Dialog open={cancelDialogOpen} onClose={handleCloseCancelDialog}>
        <DialogTitle>Cancel Purchase</DialogTitle>
        <DialogContent>
          <TextField
            label="Reason for Cancellation"
            fullWidth
            multiline
            rows={4}
            value={cancelReason}
            onChange={(e) => setCancelReason(e.target.value)}
            variant="outlined"
            margin="normal"
          />
          <DialogActions>
            <Button onClick={handleCloseCancelDialog} color="secondary">
              Close
            </Button>
            <Button onClick={handleCancelPurchase} color="primary">
              Confirm Cancellation
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      {/* Put Back In Cart Dialog */}
      <Dialog open={putBackInCartDialogOpen} onClose={handleClosePutBackInCartDialog}>
        <DialogTitle>Are you sure you want to put items back in Cart?</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1">Press Continue to put this purchase back in your Cart so you can add/modify items.</Typography>
          <DialogActions>
            <Button onClick={handleClosePutBackInCartDialog} color="secondary">
              Close
            </Button>
            <Button onClick={handlePutBackInCart} color="primary">
              Continue
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      {/* Request Refund Dialog */}
      <Dialog open={refundDialogOpen} onClose={handleCloseRefundDialog}>
        <DialogTitle>Request Refund</DialogTitle>
        <DialogContent>
          <TextField
            label="Reason for Refund"
            fullWidth
            multiline
            rows={4}
            value={refundReason}
            onChange={(e) => setRefundReason(e.target.value)}
            variant="outlined"
            margin="normal"
          />
          <FormControl component="fieldset" fullWidth margin="normal">
            <Typography variant="subtitle1">Refund Payment Method</Typography>
            <RadioGroup
              value={refundPaymentMethod}
              onChange={(e) => setRefundPaymentMethod(e.target.value)}
            >
              <FormControlLabel
                value="Bank Transfer"
                control={<Radio />}
                label="Bank Transfer"
              />
              <FormControlLabel
                value="Paypal"
                control={<Radio />}
                label="Paypal"
              />
              <FormControlLabel
                value="PayID"
                control={<Radio />}
                label="PayID"
              />
              <FormControlLabel
                value="Other"
                control={<Radio />}
                label="Other"
              />
            </RadioGroup>
          </FormControl>
          {renderRefundPaymentDetailsFields()}
          <DialogActions>
            <Button onClick={handleCloseRefundDialog} color="secondary">
              Close
            </Button>
            <Button onClick={handleRequestRefund} color="primary">
              Confirm Refund Request
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      {/* View Dispute Details Dialog */}
      <Dialog
        open={viewDisputeDialogOpen}
        onClose={() => setViewDisputeDialogOpen(false)}
      >
        <DialogTitle>Dispute Details</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1">Reason for Dispute</Typography>
          <Typography variant="body2">
            {selectedPurchase?.disputeReason || "No reason provided."}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Seller Response
          </Typography>
          <Typography variant="body2">
            {selectedPurchase?.sellerResponse || "No response provided."}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setViewDisputeDialogOpen(false)}
            color="secondary"
          >
            Close
          </Button>
          <Button onClick={handleEscalateDispute} color="warning">
            Escalate
          </Button>
          <Button onClick={handleAcceptResolution} color="primary">
            Accept Resolution
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dispute Purchase Dialog */}
      <BuyerDispute
        open={disputeDialogOpen}
        onClose={() => setDisputeDialogOpen(false)}
        onSubmit={handleRequestDispute}
        purchase={selectedPurchase}
      />

      <PaymentRequestedDialog
        open={paymentDialogOpen}
        onClose={() => setPaymentDialogOpen(false)}
        purchase={selectedPurchase}
        buyerAddress={buyerAddress}
        setBuyerAddress={setBuyerAddress}
        fetchBuyerPreferences={fetchBuyerPreferences}
        handleGetInvoice={handleGetInvoice}
        message={message}
        setMessage={setMessage}
        selectedShippingOption={selectedShippingOption}
        setSelectedShippingOption={setSelectedShippingOption}
        selectedPaymentOption={selectedPaymentOption}
        setSelectedPaymentOption={setSelectedPaymentOption}
        paymentDetails={paymentDetails}
        setPaymentDetails={setPaymentDetails}
        paymentFee={paymentFee}
        totalCost={totalCost}
        handleCancelPurchase={handleCancelPurchase}
      />

      <Dialog
        open={invoiceDialogOpen}
        onClose={() => setInvoiceDialogOpen(false)}
        maxWidth="md"
        fullWidth
        fullScreen={isMobile}
      >
        <DialogContent>
          <Invoice
            sale={selectedPurchase}
            onClose={() => setInvoiceDialogOpen(false)}
            shippingOption={selectedPurchase?.shippingOption}
            paymentOption={selectedPurchase?.paymentOption}
            totalCost={totalCost}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={trackingDialogOpen}
        onClose={() => setTrackingDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Tracking Information</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1">
            Shipping Company: {selectedPurchase?.shippingCompany || "N/A"}
          </Typography>
          <Typography variant="subtitle1">
            Tracking Number: {selectedPurchase?.trackingNumber || "N/A"}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setTrackingDialogOpen(false)}
            color="secondary"
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              handleMarkReceived(selectedPurchase.id, selectedPurchase.sellerId)
            }
          >
            Mark as Received
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={approveChangesDialogOpen}
        onClose={() => setApproveChangesDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Price/Quantity Change</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1">
          If you have reviewed the changes and you are happy to proceed with the purchase, press continue, otherwise return to the sale and press the cancel purchase button on the item
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setApproveChangesDialogOpen(false)}
            color="secondary"
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              handleApproveChanges(selectedPurchase.id, selectedPurchase.sellerId)
            }
          >
            Approve Changes
          </Button>
        </DialogActions>
      </Dialog>
      

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {selectedUserId && <PublicProfile userId={selectedUserId} />}{" "}
          {/* Pass the selectedUserId */}
        </DialogContent>
      </Dialog>

      <Dialog
        open={markAsPaidDialogOpen}
        onClose={() => setMarkAsPaidDialogOpen(false)}
      >
        <DialogTitle>Mark as Paid</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1">
            Total Owing: ${selectedPurchase?.totalCost || "N/A"}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Do you want to send a message to the seller?
          </Typography>
          <TextField
            label="Message to Seller (Optional)"
            fullWidth
            multiline
            rows={4}
            value={buyerPaymentMessage}
            onChange={(e) => setBuyerPaymentMessage(e.target.value)}
            variant="outlined"
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setMarkAsPaidDialogOpen(false)}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={async () => {
              await confirmMarkAsPaid(
                selectedPurchase.id,
                selectedPurchase.sellerId
              );
              setMarkAsPaidDialogOpen(false);
            }}
            color="primary"
          >
            Confirm Payment
          </Button>
        </DialogActions>
      </Dialog>

      <RateSellerDialog
        open={rateSellerDialogOpen}
        onClose={() =>
          handleMarkRated(selectedPurchase.id, selectedPurchase.sellerId)
        }
        sale={selectedPurchase}
      />
      {selectedPurchase && (
        <ImageViewerDialog
          open={imageDialogOpen}
          onClose={handleCloseImageViewer}
          saleId={selectedPurchase.id}
          sellerId={selectedPurchase.sellerId}
        />
      )}
      <MessageBox
        open={messageBoxOpen}
        title={messageBoxContent.title}
        message={messageBoxContent.message}
        onClose={() => setMessageBoxOpen(false)}
      />
    </Container>
  );
};

export default BuyerPurchases;
