import React, { useState } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button, Box, Paper, Divider
} from '@mui/material';
import Invoice from './Invoice';
import SaleItemsTable from './SaleItemsTable';

const PrepareShippingDialog = ({ open, onClose, sale, onMarkPreparing }) => {
  const [printDialogOpen, setPrintDialogOpen] = useState(false);

  const handlePrint = () => {
    setPrintDialogOpen(true);
  };

  const handleMarkPreparing = () => {
    onMarkPreparing(sale.id, sale.buyerId);
    onClose();
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Prepare for Shipping</DialogTitle>
        <DialogContent>
        {sale?.buyerPaymentMessage && (
        <Box>
        <Typography variant="subtitle" gutterBottom>
        Message from Buyer
      </Typography>
  <Box 
    sx={{
      border: '1px solid #ccc', // Light gray border
      borderRadius: '4px', // Optional: rounded corners
      padding: '16px', // Space inside the box
      marginBottom: '16px', // Optional: space below the box
    }}
  >
    <Typography variant="body1" paragraph>
      {sale?.buyerPaymentMessage}
    </Typography>
  </Box>
  </Box>
)}
          {/* Items List */}
          <SaleItemsTable sale={sale} />

          <Divider sx={{ my: 2 }} />

          {/* Buyer Address Section */}
          <Box component={Paper} sx={{ padding: 2, mb: 2 }} elevation={1}>
            <Typography variant="h6" gutterBottom>Buyer Address</Typography>
            {sale?.buyerAddress ? (
              <Box>
                {sale.buyerAddress.preferredContactName && <Typography variant="body2">{sale.buyerAddress.preferredContactName}</Typography>}
                {sale.buyerAddress.addressLine1 && <Typography variant="body2">{sale.buyerAddress.addressLine1}</Typography>}
                {sale.buyerAddress.addressLine2 && <Typography variant="body2">{sale.buyerAddress.addressLine2}</Typography>}
                {sale.buyerAddress.city && <Typography variant="body2">{sale.buyerAddress.city}</Typography>}
                {sale.buyerAddress.state && <Typography variant="body2">{sale.buyerAddress.state}</Typography>}
                {sale.buyerAddress.zipCode && <Typography variant="body2">{sale.buyerAddress.zipCode}</Typography>}
                {sale.buyerAddress.country && <Typography variant="body2">{sale.buyerAddress.country}</Typography>}
                {sale.buyerAddress.additionalDetails && <Typography variant="body2">{sale.buyerAddress.additionalDetails}</Typography>}
              </Box>
            ) : (
              <Typography variant="body2">No address available</Typography>
            )}
          </Box>

          {/* Shipping and Payment Information */}
          <Box component={Paper} sx={{ padding: 2, mb: 2 }} elevation={1}>
            <Typography variant="h6" gutterBottom>Shipping Information</Typography>
            <Typography variant="body2"><strong>Method:</strong> {sale?.shippingOption || 'N/A'}</Typography>
            <Typography variant="body2"><strong>Cost:</strong> {sale?.totalCost ? `$${(sale.totalCost - sale.total).toFixed(2)}` : 'N/A'}</Typography>
          </Box>

          <Box component={Paper} sx={{ padding: 2, mb: 2 }} elevation={1}>
            <Typography variant="h6" gutterBottom>Payment Information</Typography>
            <Typography variant="body2"><strong>Method:</strong> {sale?.paymentOption?.method || 'N/A'}</Typography>
            <Typography variant="h6" gutterBottom>Payment Details</Typography>
            {typeof sale?.paymentOption?.details === 'object' ? (
              Object.keys(sale.paymentOption.details).map((key, index) => (
                <Typography key={index} variant="body2">{`${key}: ${sale.paymentOption.details[key]}`}</Typography>
              ))
            ) : (
              <Typography variant="body2">{sale?.paymentOption?.details || 'N/A'}</Typography>
            )}
          </Box>

          <Divider sx={{ my: 2 }} />

          <Button onClick={handlePrint} variant="outlined" fullWidth>
            View Invoice
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Close
          </Button>
          <Button onClick={handleMarkPreparing} variant="contained" color="primary">
            Mark as Preparing
          </Button>
        </DialogActions>
      </Dialog>

      {/* Print Dialog */}
      <Dialog open={printDialogOpen} onClose={() => setPrintDialogOpen(false)} maxWidth="md" fullWidth fullScreen>
        <DialogContent>
          <Invoice sale={sale} onClose={() => setPrintDialogOpen(false)} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PrepareShippingDialog;
