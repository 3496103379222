import React, { useCallback, useState, useEffect } from 'react';
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Box,
  Typography,
  Stack,
  Chip,
  Checkbox,
  FormControlLabel,
  Grid,
  Fab,
  TextField,
  MenuItem,
  Button,
  IconButton,
} from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { collection, getDocs, doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { rarityIcons, inkColorMap } from '../marketplace/constants';
import { useAuth } from '../auth';

const PublicProfileCardList = ({ cards, currentUser, convertPrice }) => {
  const [listings, setListings] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const { currentUser: authUser } = useAuth(); // Get the current user

  const [selectedSet, setSelectedSet] = useState('');
  const [selectedCardType, setSelectedCardType] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState('collector_number');

  const fetchListings = async (card) => {
    try {
      const listingsSnapshot = await getDocs(
        collection(db, 'sets', card.setId, 'cards', card.docId, 'listings')
      );
      const listingsData = listingsSnapshot.docs.reduce((acc, doc) => {
        if (doc.id === currentUser.uid) {
          const data = doc.data();
          acc[doc.id] = Object.entries(data).map(([condition, listing]) => ({
            condition,
            ...listing,
            docName: doc.id,
          }));
        }
        return acc;
      }, {});
      setListings((prevListings) => ({
        ...prevListings,
        [card.docId]: listingsData,
      }));
    } catch (error) {
      console.error('Error fetching listings:', error);
    }
  };

  const addToCart = async (selectedListings) => {
    if (!authUser) {
      alert('You must be logged in to add items to the cart.');
      return;
    }

    const userId = authUser.uid;
    const cartRef = doc(db, 'carts', userId);
    const cartDoc = await getDoc(cartRef);
    let cartData = cartDoc.exists() ? cartDoc.data() : { items: [] };

    selectedListings.forEach(({ listing, card }) => {
      if (!card.setId || !card.docId || !listing.docName) {
        console.error('Missing setId, docName, or listingId:', { card, listing });
        return;
      }

      const finalPrice = listing.autoUpdatePrice
        ? (card.price * (listing.percentage / 100)).toFixed(2)
        : listing.price;

      const existingItemIndex = cartData.items.findIndex(
        (item) =>
          item.docName === card.docId &&
          item.type === 'listing' &&
          item.listingId === listing.docName &&
          item.condition === listing.condition
      );

      if (existingItemIndex > -1) {
        cartData.items[existingItemIndex].quantity += 1;
      } else {
        const newItem = {
          type: 'listing',
          setId: card.setId,
          docName: card.docId,
          listingId: listing.docName,
          condition: listing.condition,
          quantity: 1,
          price: finalPrice,
          image_uris: listing.image_uris || {},
          name: card.name || '',
          sellerId: listing.userId || '',
          ink: card.ink || '',
          rarity: card.rarity || '',
          version: card.version || '',
          collector_number: card.collector_number || '',
        };

        for (const key in newItem) {
          if (newItem[key] === undefined) {
            delete newItem[key];
          }
        }

        cartData.items.push(newItem);
      }
    });

    await setDoc(cartRef, cartData);
    alert('Items added to cart!');
  };

  const toggleSelectItem = (listing, card) => {
    const itemId = `${card.docId}-${listing.condition}`;

    setSelectedItems((prevSelected) => {
      if (prevSelected.some((item) => item.itemId === itemId)) {
        return prevSelected.filter((item) => item.itemId !== itemId);
      } else {
        return [...prevSelected, { listing, card, itemId }];
      }
    });
  };

  useEffect(() => {
    cards.forEach((card) => {
      fetchListings(card);
    });
  }, [cards]);

  const sortCards = (cards) => {
    switch (sortField) {
      case 'collector_number':
        return cards.sort(
          (a, b) => parseInt(a.collector_number, 10) - parseInt(b.collector_number, 10)
        );
      case 'name':
        return cards.sort((a, b) => a.name.localeCompare(b.name));
      case 'set':
        return cards.sort((a, b) => {
          if (a.set.name === b.set.name) {
            return parseInt(a.collector_number, 10) - parseInt(b.collector_number, 10);
          } else {
            return a.set.name.localeCompare(b.set.name);
          }
        });
      default:
        return cards;
    }
  };

  const filteredCards = cards.filter((card) => {
    const matchesSet = selectedSet ? card.set.name === selectedSet : true;
    const matchesType = selectedCardType ? card.type === selectedCardType : true;
    const matchesSearchTerm = searchTerm
      ? [card.name, card.set.name, card.type]
          .join(' ')
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      : true;

    return matchesSet && matchesType && matchesSearchTerm;
  });

  const sortedFilteredCards = sortCards(filteredCards);

  const handleSetChange = (event) => {
    setSelectedSet(event.target.value);
  };

  const handleCardTypeChange = (event) => {
    setSelectedCardType(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSortChange = (event) => {
    setSortField(event.target.value);
  };

  const handleSelectAll = () => {
    const allItems = sortedFilteredCards.flatMap((card) => {
      const cardListings = listings[card.docId] || [];
      return Object.values(cardListings).flatMap((userListings) =>
        userListings.map((listing) => ({
          listing,
          card,
          itemId: `${card.docId}-${listing.condition}`,
        }))
      );
    });

    setSelectedItems((prevSelected) => {
      const existingItemIds = new Set(prevSelected.map((item) => item.itemId));
      const newItems = allItems.filter((item) => !existingItemIds.has(item.itemId));
      return [...prevSelected, ...newItems];
    });
  };

  const handleSelectNone = () => {
    setSelectedItems([]);
  };

  const renderCard = useCallback(
    (card) => {
      const cardListings = listings[card.docId] || [];

      return Object.values(cardListings).reduce((acc, userListings) => {
        return acc.concat(
          userListings.map((listing, index) => {
            const itemId = `${card.docId}-${listing.condition}`;
            const isSelected = selectedItems.some((item) => item.itemId === itemId);

            const finalPrice = listing.autoUpdatePrice
              ? (card.price * (listing.percentage / 100)).toFixed(2)
              : listing.price;

            return (
              <ListItem
                key={itemId}
                sx={{
                  border: "2px solid #0C0A5A",
                  flexDirection: { xs: "column", sm: "row" },
                }}
              >
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={12} sm={2}>
                    <ListItemAvatar sx={{ width: { xs: "100%", sm: "100px" } }}>
                      <Avatar
                        className={card.type === "Foil" ? "foil-avatar" : ""}
                        variant="square"
                        src={
                          card.image_uris?.digital?.small ||
                          "fallback_image_url"
                        }
                        sx={{
                          height: "20%",
                          width: "100%",
                          objectFit: "contain",
                          border: `4px solid ${
                            inkColorMap[card.ink] || "#F2D18B"
                          }`,
                        }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "fallback_image_url";
                        }}
                      />
                      {card.type === "Foil" && (
                        <Avatar
                          variant="square"
                          sx={{
                            width: "100%",
                            height: 10,
                            bgcolor: "#ffd700",
                            border: "2px solid #000000",
                            color: "#000000",
                            bottom: 18,
                            left: 4,
                          }}
                        >
                          <Typography variant="h6" sx={{ fontSize: "10px" }}>
                            COLD FOIL
                          </Typography>
                        </Avatar>
                      )}
                    </ListItemAvatar>
                  </Grid>
                  <Grid item xs={3} sm={2}>
                    <Stack spacing={1} alignItems="left" direction="column">
                      <Avatar
                        variant="square"
                        sx={{
                          bgcolor: "#0C0A5A",
                          width: { xs: "40px", sm: "60px" },
                          height: { xs: "40px", sm: "60px" },
                        }}
                      >
                        {card.collector_number}
                      </Avatar>
                      <Avatar
                        variant="square"
                        sx={{
                          bgcolor: "#0C0A5A",
                          width: { xs: "40px", sm: "60px" },
                          height: { xs: "40px", sm: "60px" },
                        }}
                      >
                        <img
                          src={rarityIcons[card.rarity]}
                          alt={card.rarity}
                          style={{ width: 24, height: 24 }}
                        />
                      </Avatar>
                    </Stack>
                  </Grid>
                  <Grid item xs={9} sm={4}>
                    <ListItemText
                      primary={
                        <Typography variant="body1">{card.name}</Typography>
                      }
                      secondary={
                        <>
                          {card.version && (
                            <Typography variant="body2" color="textSecondary">
                              {card.version}
                            </Typography>
                          )}
                          {card.set?.name && (
                            <Box mt={2}>
                              <Typography variant="body2" color="textSecondary">
                                {card.set.name}
                              </Typography>
                            </Box>
                          )}
                        </>
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "stretch", // Ensure full height alignment
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        gap: 1,
                      }}
                    >
                      <Chip
                        label={
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <span>Condition</span>
                            {listing.condition}
                          </Box>
                        }
                        sx={{
                          width: 130,
                          bgcolor: "green",
                          color: "white",
                          alignItems: "center",
                        }}
                      />
                      <Chip
                        label={
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <span>Qty Available</span>
                            {listing.quantity}
                          </Box>
                        }
                        sx={{
                          width: 130,
                          bgcolor: "secondary.main",
                          color: "white",
                          alignItems: "center",
                        }}
                      />
                      <Chip
                        clickable
                        component="a"
                        href={`https://www.tcgplayer.com/product/${card.tcgplayer_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          width: 130,
                          bgcolor: "info.main",
                          color: "white",
                        }}
                        label={
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <span>Price</span>${convertPrice(finalPrice || 0)}
                          </Box>
                        }
                      />
                    </Box>

                    {/* The last box with the checkbox should stretch to full height */}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end", // Align at the end horizontally
                        ml: "auto", // Push it to the far right
                        minWidth: "30%", // Control the width of the right-side box
                      }}
                    >
                      {authUser &&
                        authUser.verificationStatus === "Verified" && (
                          <IconButton
                            color="secondary"
                            onClick={() => toggleSelectItem(listing, card)} // Handle click action for adding/removing item
                            sx={{
                              padding: "0", 
                            }}
                          >
                            {selectedItems.some(
                              (item) =>
                                item.itemId ===
                                `${card.docId}-${listing.condition}`
                            ) ? (
                              <CheckCircleIcon
                                sx={{ fontSize: 60, color: "green" }}
                              />
                            ) : (
                              <AddShoppingCartIcon sx={{ fontSize: 60, color: "gray" }} />
                            )}
                          </IconButton>
                        )}
                    </Box>
                  </Grid>
                </Grid>
              </ListItem>
            );
          })
        );
      }, []);
    },
    [listings, convertPrice, selectedItems, toggleSelectItem, authUser]
  );

  return (
    <Box sx={{ pt: 2, pb: "150px" }}>
      {/* Filters and Sort Options */}
      <Box
        sx={{
          marginBottom: 2,
          display: "flex",
          gap: 2,
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <TextField
          select
          label="Filter by Set"
          value={selectedSet}
          onChange={handleSetChange}
          fullWidth
        >
          <MenuItem value="">
            <em>All Sets</em>
          </MenuItem>
          {[...new Set(cards.map((card) => card.set.name))].map((setName) => (
            <MenuItem key={setName} value={setName}>
              {setName}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          select
          label="Filter by Card Type"
          value={selectedCardType}
          onChange={handleCardTypeChange}
          fullWidth
        >
          <MenuItem value="">
            <em>All Types</em>
          </MenuItem>
          {[...new Set(cards.map((card) => card.type || "Normal"))].map(
            (type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            )
          )}
        </TextField>

        <TextField
          label="Search"
          value={searchTerm}
          onChange={handleSearchChange}
          fullWidth
        />

        <TextField
          select
          label="Sort By"
          value={sortField}
          onChange={handleSortChange}
          fullWidth
        >
          <MenuItem value="">
            <em>No Sorting</em>
          </MenuItem>
          <MenuItem value="collector_number">Card Number</MenuItem>
          <MenuItem value="name">Card Name</MenuItem>
          <MenuItem value="set">Set Name</MenuItem>
        </TextField>
      </Box>

      {authUser && authUser.verificationStatus === "Verified" && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="body1">
            {selectedItems.length} card{selectedItems.length !== 1 ? "s" : ""}{" "}
            selected
          </Typography>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              variant="contained"
              onClick={() => {
                if (selectedItems.length === sortedFilteredCards.length) {
                  handleSelectNone(); // Deselect all items
                } else {
                  handleSelectAll(); // Select all items
                }
              }}
            >
              {selectedItems.length === sortedFilteredCards.length
                ? "Select None"
                : "Select All"}
            </Button>
          </Box>
        </Box>
      )}

      <List>{sortedFilteredCards.flatMap(renderCard)}</List>

      {authUser &&
        authUser.verificationStatus === "Verified" &&
        selectedItems.length > 0 && (
          <Fab
            variant="extended"
            color="primary"
            aria-label="add to cart"
            onClick={() => addToCart(selectedItems)}
            sx={{
              position: "fixed",
              bottom: 16,
              right: 16,
              zIndex: 1000,
              padding: "0 16px",
              fontSize: "1rem",
            }}
          >
            Add {selectedItems.length} item{selectedItems.length > 1 ? "s" : ""}{" "}
            to Cart
            <ShoppingCartIcon sx={{ ml: 1 }} />
          </Fab>
        )}
    </Box>
  );
};

export default React.memo(PublicProfileCardList);
