import React, { useState, useEffect } from 'react';
import {
  ListSubheader,
  Grid,
  Box,
  Typography,
  Button,
  List,
  ListItem,
  Link,
  IconButton,
  Divider,
  FormControlLabel,
  Switch,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import SendIcon from '@mui/icons-material/Send';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SearchSortToolbar from './SearchSortToolbar';
import SaleItemsTable from './SaleItemsTable'; // Can be reused as PurchaseItemsTable

// Group purchases by date
const groupPurchasesByDate = (purchases) => {
  return purchases.reduce((groups, purchase) => {
    const date = purchase.timestamp ? new Date(purchase.timestamp.seconds * 1000).toLocaleDateString() : 'Unknown Date';
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(purchase);
    return groups;
  }, {});
};

// Sorting logic
const sortPurchases = (purchases, sortOption) => {
  return purchases.sort((a, b) => {
    if (sortOption === 'date') {
      return b.timestamp.seconds - a.timestamp.seconds; // Newest first
    } else if (sortOption === 'total') {
      return b.total - a.total; // Highest total first
    }
    return 0;
  });
};

const PurchaseList = ({
  purchases,
  usernames,
  onOpenProfile,
  onOpenCancelDialog,
  onOpenRefundDialog,
  onOpenPaymentDialog,
  setSelectedPurchase,
  onOpenDisputeDialog,
  onOpenInvoiceDialog,
  onOpenTrackingDialog,
  onOpenApproveChangesDialog,
  onOpenImageViewer,
  onMarkAsPaid,
  onRateSeller,
  onPutBackInCart,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('date');
  const [collapsedSections, setCollapsedSections] = useState({});
  const [collapsedPurchases, setCollapsedPurchases] = useState({});
  const [allCollapsed, setAllCollapsed] = useState(false);
  const [showCancelled, setShowCancelled] = useState(false); // Toggle for showing Cancelled purchases
  const [showCompleted, setShowCompleted] = useState(false); 

  useEffect(() => {
    const initialCollapsedState = purchases.reduce((acc, purchase) => {
      acc[purchase.id] = true;
      return acc;
    }, {});
    setCollapsedPurchases(initialCollapsedState);
  }, [purchases]);

  const getStatusColor = (status) => {
    switch (status) {
      case 'Paid':
        return '#28a745'; // Dark Green
      case 'Completed':
        return '#90ee90'; // Light Green
      case 'Cancelled':
        return '#dc3545'; // Red
      case 'Pending':
        return '#ffc107'; // Yellow
      default:
        return '#ffc107'; // Grey for other statuses
    }
  };
  

  const getTotalCards = (items) => {
    return items.reduce((total, item) => total + item.staticQuantity, 0);
  };

  const filteredPurchases = purchases.filter((purchase) => {
    const sellerName = usernames[purchase.sellerId] || purchase.sellerId;
    const searchInItems = purchase.items?.some((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
      )
    );

    const showCancelledPurchase = purchase.status === 'Cancelled' && showCancelled;
    const showCompletedPurchase = purchase.status === 'Completed' && showCompleted;

    return (
      (sellerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        purchase.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
        String(purchase.total).includes(searchTerm) || // Allow search by total
        searchInItems) &&
      ((purchase.status !== 'Cancelled' || showCancelledPurchase) &&
        (purchase.status !== 'Completed' || showCompletedPurchase))
    );
  });

  const sortedPurchases = sortPurchases(filteredPurchases, sortOption);
  const groupedPurchases = groupPurchasesByDate(sortedPurchases);

  const toggleCollapse = (date) => {
    setCollapsedSections((prev) => ({
      ...prev,
      [date]: !prev[date],
    }));
  };

  const togglePurchaseCollapse = (purchaseId) => {
    setCollapsedPurchases((prev) => ({
      ...prev,
      [purchaseId]: !prev[purchaseId],
    }));
  };

  const toggleAllSections = (collapse) => {
    const newState = Object.keys(groupedPurchases).reduce((acc, date) => {
      acc[date] = collapse;
      return acc;
    }, {});
    setCollapsedSections(newState);
    setAllCollapsed(collapse);
  };

  return (
    <Box>
      <SearchSortToolbar
        searchTerm={searchTerm}
        sortOption={sortOption}
        onSearchChange={setSearchTerm}
        onSortChange={setSortOption}
        onToggleCollapse={toggleAllSections}
        allCollapsed={allCollapsed}
      />

      <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
        <FormControlLabel
          control={
            <Switch
              checked={showCancelled}
              onChange={() => setShowCancelled(!showCancelled)}
              color="success"
            />
          }
          label="Cancelled"
        />
        <FormControlLabel
          control={
            <Switch
              checked={showCompleted}
              onChange={() => setShowCompleted(!showCompleted)}
              color="success"
            />
          }
          label="Completed"
        />
      </Box>

      {/* List of Purchases */}
      <List>
        {Object.keys(groupedPurchases).map((date) => (
          <React.Fragment key={date}>
            <ListSubheader
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">{date}</Typography>
              <IconButton onClick={() => toggleCollapse(date)}>
                {collapsedSections[date] ? (
                  <ExpandMoreIcon />
                ) : (
                  <ExpandLessIcon />
                )}
              </IconButton>
            </ListSubheader>

            {!collapsedSections[date] &&
              groupedPurchases[date].map((purchase) => (
                <React.Fragment key={purchase.id}>
                  <ListItem
                    sx={{
                      border: "2px solid #0C0A5A",
                      borderRadius: 2,
                      marginBottom: 2,
                      padding: 2,
                    }}
                  >
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} sm={8}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                            padding: 2,
                            backgroundColor: "#f5f5f5",
                            borderRadius: 2,
                            boxShadow: 1,
                          }}
                        >
                          {/* Seller Information */}
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: "bold" }}
                            >
                              Seller:
                            </Typography>
                            <Link
                              onClick={() => onOpenProfile(purchase.sellerId)}
                              sx={{
                                ml: 1,
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                                color: "#007bff",
                                textDecoration: "none",
                              }}
                            >
                              <PersonIcon
                                fontSize="small"
                                sx={{ marginRight: "5px" }}
                              />
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  fontWeight: "bold",
                                  fontSize: "1rem",
                                }}
                              >
                                {usernames[purchase.sellerId]}
                              </Typography>
                            </Link>
                          </Box>

                          <Divider sx={{ my: 1 }} />

                          {/* Total and Purchase Status */}
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "1.1rem" }}
                            >
                              Total:{" "}
                              <span style={{ fontWeight: "bold" }}>
                                ${purchase.total.toFixed(2)}
                              </span>
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                backgroundColor: getStatusColor(
                                  purchase.status
                                ),
                                color: "#fff",
                                fontWeight: "bold",
                                padding: "4px 10px",
                                borderRadius: "4px",
                              }}
                            >
                              {purchase.status}
                            </Typography>
                          </Box>

                          <Typography variant="body1" sx={{ fontSize: "1rem" }}>
                            Cards:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {getTotalCards(purchase.items)}
                            </span>
                          </Typography>

                          {purchase.refundStatus && (
                            <Typography variant="body2" color="error">
                              Refund Status: {purchase.refundStatus}
                            </Typography>
                          )}

                          {/* Cancel Purchase Button */}
                          {[
                            "Pending",
                            "Invoice Generated",
                            "Payment Requested",
                            "Buyer Approved",
                            "Price/Quantity Modified",
                          ].includes(purchase.status) && (
                            <Button
                              variant="text"
                              color="error"
                              onClick={() => onOpenCancelDialog(purchase)}
                              fullWidth
                            >
                              Cancel Purchase
                            </Button>
                          )}
                          {/* Back in Cart Button */}
                          {[
                            "Pending",
                          ].includes(purchase.status) && (
                            <Button
                                variant="text"
                                color="primary"
                                onClick={() => onPutBackInCart(purchase)}
                                fullWidth
                              >
                                Put Back in Cart
                              </Button>
                          )}
                        </Box>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={4}
                        sx={{ textAlign: "right" }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                          }}
                        >
                          {(purchase.status === "Invoice Generated" ||
                            purchase.status === "Paid" ||
                            purchase.status === "Shipped" ||
                            purchase.status === "Completed" ||
                            purchase.status === "Received") && (
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => onOpenInvoiceDialog(purchase)}
                            >
                              View Invoice
                            </Button>
                          )}

                          {purchase.status !== "Paid" &&
                            purchase.status === "Invoice Generated" && (
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => onMarkAsPaid(purchase)}
                              >
                                Mark as Paid
                              </Button>
                            )}

                          {purchase.status !== "Invoice Generated" &&
                            purchase.status !== "Paid" &&
                            purchase.status === "Payment Requested" && (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => onOpenPaymentDialog(purchase)}
                                endIcon={<SendIcon />}
                              >
                                Get Invoice
                              </Button>
                            )}

                          {purchase.status === "Shipped" && (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => onOpenTrackingDialog(purchase)}
                            >
                              View Tracking
                            </Button>
                          )}

                          {purchase.status === "Price/Quantity Modified" && (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => onOpenApproveChangesDialog(purchase)}
                            >
                              Approve Price/Qty Changes
                            </Button>
                          )}

                          {["Paid", "Preparing for Shipping"].includes(
                            purchase.status
                          ) && (
                            <Box sx={{ marginTop: 2 }}>
                              <Button
                                variant="text"
                                color="warning"
                                onClick={() => onOpenRefundDialog(purchase)}
                                fullWidth
                              >
                                Request Refund
                              </Button>
                            </Box>
                          )}

                          {purchase.status === "Dispute Denied" && (
                            <Box sx={{ marginTop: 2 }}>
                              <Button
                                variant="text"
                                color="warning"
                                onClick={() => onOpenDisputeDialog(purchase)}
                                fullWidth
                              >
                                View Dispute Details
                              </Button>
                            </Box>
                          )}

                          {purchase.status === "Received" && (
                            <>
                              <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={() => onOpenImageViewer(purchase)}
                              >
                                View Card Condition Photos
                              </Button>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => onRateSeller(purchase)}
                              >
                                Rate Seller
                              </Button>
                            </>
                          )}
                        </Box>
                      </Grid>

                      {/* Purchase Items Table with toggle button */}
                      <Grid item xs={12}>
                        <Divider />
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            mt: 2,
                          }}
                        >
                          <IconButton
                            onClick={() => togglePurchaseCollapse(purchase.id)}
                          >
                            {collapsedPurchases[purchase.id] ? (
                              <Typography variant="body1">
                                Show Details
                              </Typography>
                            ) : (
                              <Typography variant="body1">
                                Hide Details
                              </Typography>
                            )}
                          </IconButton>
                        </Grid>

                        {!collapsedPurchases[purchase.id] && (
                          <SaleItemsTable sale={purchase} />
                        )}
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default PurchaseList;
